import background from './background.mjs'
import { imgproxySettings, mobileImgproxySettings } from './shared.mjs'
import { reflection } from './classics3.mjs'

export const body = {
  id: 'body',
  name: 'Body',
  imgproxySettings,
  mobileImgproxySettings,
  hasOptions: false,
  pathTemplate: 'gs://sg360/220704/classics-le_sas/classics3-le_sas_{nnn}.png'
}

export default {
  layers: [
    background,
    body,
    reflection
  ],
  resolveOptions: () => ({
    reflections: 'r5pc'
  })
}