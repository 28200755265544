export type MarketShippingPrices = {
  domestic: number
  international: number
  letter: number
}

export type Market = {
  currency: string
  symbol: string
  label: string
  testId: number
  productionId: number
  shipping: MarketShippingPrices
}

const markets: { [key: string]: Market } = {
  GBP: {
    currency: 'GBP',
    symbol: '£',
    label: 'British Pounds',
    testId: 6866,
    productionId: 9150,
    shipping: { domestic: 6.0, international: 12.0, letter: 1.25 },
  },
  EUR: {
    currency: 'EUR',
    symbol: '€',
    label: 'Euros',
    testId: 6867,
    productionId: 9149,
    shipping: { domestic: 8.0, international: 14.0, letter: 1.5 },
  },
  USD: {
    currency: 'USD',
    symbol: '$',
    label: 'US Dollars',
    testId: 6868,
    productionId: 9148,
    shipping: { domestic: 8.0, international: 14.0, letter: 1.5 },
  },
  CHF: {
    currency: 'CHF',
    symbol: 'CHF',
    label: 'Swiss Francs',
    testId: 6870,
    productionId: 9146,
    shipping: { domestic: 8.0, international: 14.0, letter: 1.5 },
  },
  CAD: {
    currency: 'CAD',
    symbol: '$',
    label: 'Canadian Dollars',
    testId: 6869,
    productionId: 9147,
    shipping: { domestic: 11.0, international: 20.0, letter: 2.5 },
  },
  NZD: {
    currency: 'NZD',
    symbol: '$',
    label: 'New Zealand Dollars',
    testId: 6872,
    productionId: 9144,
    shipping: { domestic: 13.0, international: 24.0, letter: 2.5 },
  },
  AUD: {
    currency: 'AUD',
    symbol: '$',
    label: 'Australian Dollars',
    testId: 6871,
    productionId: 9145,
    shipping: { domestic: 12.0, international: 22.0, letter: 2.5 },
  },
}

export default markets