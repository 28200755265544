import { reactive } from 'vue'

export default class ProductOption {
  constructor (data) {
    this._rawData = data
    delete this._rawData.name
    delete this._rawData?.productOptions

    Object.keys(this._rawData).forEach((key) => {
      this[key] = this._rawData[key]
    })

    this.productOptionId = this._rawData.id
    this._name = this._rawData.optionTech?.displayTitle || this._rawData.name
    this.scmId = this._rawData.scm_id
    // Trick Vue into thinking this instance already has an observer to prevent it adding a new one
    this.__ob__ = reactive({}).__ob__
  }

  get price () {
    return this.amountFloat
  }

  get priceGBP() {
    return this.amountFloatGBP
  }

  get compareAtPrice () {
    return this.compareAtAmountFloat
  }

  get lensTech () {
    return this.optionTech.tech
  }

  get skuWithoutLensTech () {
    return this.sku
  }

  get skuWithoutPrefix () {
    return this.sku.split('_')?.[1]
  }

  get isInStock () {
    return this.quantity > 0 || this.doNotTrackStock
  }

  get isOnPreorder () {
    return (
      !this.isInStock && this.inboundDeliveryDate && this.allowPreorders
    )
  }

  get name () {
    return this._name
  }
  
  toJSON () {
    return this._rawData
  }

  static fromJSON (data) {
    return new ProductOption(data)
  }
}
