export default (ra) => ({
  async retailer (data) {
    await ra.track('Warranty Activation Retailer Selected', data)
  },

  async start (data) {
    await ra.track('Warranty Activation Started', data)
  },

  async complete (data) {
    await ra.track('Warranty Activation Complete', data)
  },
})
