import { imgproxySettings, mobileImgproxySettings } from './shared.mjs'
import { lenses, engraving, icons } from './velans.mjs'

export const frame = {
  id: 'frame',
  name: 'Frame',
  imgproxySettings,
  mobileImgproxySettings,
  hasOptions: false,
  pathTemplate: 'gs://sg360/220713/Velans/Velans_Momentums_FF/vef/vef_Momentum_{nnn}.png'
}

export const bottom = {
  id: 'bottom',
  name: 'Bottom',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    none: {
      disabled: true
    },
    enabled: {
      pathTemplate: 'gs://sg360/220713/Velans/Velans_Momentums_FF/veb/veb_Momentums_{nnn}.png'
    }
  }
}

export const nose = {
  id: 'nose',
  name: 'Nose',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    none: {
      disabled: true
    },
    enabled: {
      pathTemplate: 'gs://sg360/220713/Velans/Velans_Momentums_TF/ven/ven_Momentums_{nnn}.png'
    }
  }
}

export const earsocks = {
  id: 'earsocks',
  name: 'Earsocks',
  imgproxySettings,
  mobileImgproxySettings,
  hasOptions: false,
  pathTemplate: 'gs://sg360/220713/Velans/Velans_Momentums_TF/vees/vees_Momentums_{nnn}.png'
}

function resolveOptions (customisedProduct) {
  const frameType = customisedProduct.frameType.toUpperCase()
  
  return {
    bottom: frameType === 'FF' ? 'enabled' : 'none',
    nose: frameType === 'FF' ? 'none' : 'enabled',
    icons: 'Bwhite',
    engraving: 'eN',
    lens: 'gold' + frameType
  }
}

function resolveSecondaryOptions (customisedProduct) {
  const frameType = customisedProduct.frameType.toUpperCase()

  return {
    bottom: frameType === 'FF' ? 'none' : 'enabled',
    nose: frameType === 'FF' ? 'enabled' : 'none'
  }
}

function resolveOptionsFromSKU (sku) {
  return {
    bottom: 'none',
    nose: 'enabled',
    icons: 'Bwhite',
    engraving: 'eN',
    lens: 'goldTF'
  }
}

export default {
  layers: [
    frame,
    earsocks,
    bottom,
    nose,
    icons,
    lenses,
    engraving
  ],
  resolveOptions,
  resolveOptionsFromSKU,
  resolveSecondaryOptions
}