const getLensGuidePayload = customisedProduct => ({
  sku: customisedProduct.sku,
  legacy_sku: customisedProduct.legacySKU,
  base_sku: customisedProduct.baseSKU,
  name: customisedProduct.name,
  lens_choice_sku: customisedProduct.lensChoice?.sku,
  lens_choice_name: customisedProduct.lensChoice?.plaintextName,
  lens_tech: customisedProduct.lensTech
})

export default ra => ({
  async open (customisedProduct, location) {
    await ra.track('Lens Guide Opened', {
      ...getLensGuidePayload(customisedProduct),
      location
    })
  },

  async close (customisedProduct) {
    await ra.track('Lens Guide Closed', getLensGuidePayload(customisedProduct))
  }
})