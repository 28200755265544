import { imgproxySettings, mobileImgproxySettings } from './shared.mjs'

export const frame = {
  id: 'frame',
  name: 'Frame',
  imgproxySettings,
  mobileImgproxySettings,
  hasOptions: false,
  pathTemplate: 'gs://sg360/240717/Miras/mf_Igrape_{nnn}.png',
}

export const lens = {
  id: 'lens',
  name: 'Lenses',
  imgproxySettings,
  mobileImgproxySettings,
  hasOptions: false,
  pathTemplate: 'gs://sg360/240717/Miras/mle_grape_{nnn}.png',
}

export const logos = {
  id: 'logos',
  name: 'Logos',
  imgproxySettings,
  mobileImgproxySettings,
  hasOptions: false,
  pathTemplate: 'gs://sg360/240717/Miras/mi_grape_{nnn}.png',
}

export default {
  supports360: false,
  layers: [
    frame,
    lens,
    logos
  ],
  resolveOptions: () => ({})
}