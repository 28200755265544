const trackedExperiments = []

export default (ra) => ({
  async view(experiment) {
    if (
      trackedExperiments.includes(
        `${experiment.id}:${experiment.activeVariant}`
      )
    )
      return

    await ra.track("Experiment Viewed", {
      experiment_label: experiment.label,
      experiment_key: experiment.key,
      experiment_id: experiment.id,
      variant_id: experiment.activeVariant,
      variant_label: experiment.variants?.[experiment.activeVariant]?.label,
    })

    trackedExperiments.push(`${experiment.id}:${experiment.activeVariant}`)
  },
})
