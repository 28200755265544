import { resolveUrlParams } from '~/assets/js/helpers/url'

const imageTransforms = {
  defaultOg: { fit: 'crop', w: 1920, h: 1080 },
  defaultTwitter: { fit: 'crop', crop: 'entropy', w: 1920, ar: '2:1' }
}

const getTag = type => meta => meta.tag === type
const getMeta = getTag('meta')
const getLink = getTag('link')
const getAttributes = m => m.attributes

const getImageTagContent = (tag, transform) => {
  const { property, name } = tag
  const content = resolveUrlParams(tag.content, transform)

  return name
    ? { name, content }
    : { property, content }
}

const getImageMetaAttribute = (tag) => {
  if (!tag) return

  if (tag.property === 'og:image')
    return getImageTagContent(tag, imageTransforms.defaultOg)

  if (tag.name === 'twitter:image')
    return getImageTagContent(tag, imageTransforms.defaultTwitter)

  return tag
}

const addTitleSuffix = (title) => {
  if (!title) return title

  const suffix = ' | SunGod. See Better.'
  const maxTitleLength = 60
  if (title.length + suffix.length < maxTitleLength) return title + suffix
  return title
}

export default defineNuxtPlugin((nuxtApp) => {
  function buildHead(tags) {
    if (!tags) return

    const locale = nuxtApp.$i18n.localeProperties.value.iso

    const title = addTitleSuffix(tags.filter(obj => obj.tag === 'title')[0].content)

    const updatedMetaTags = tags
      .filter(getMeta)
      .map(getAttributes)
      .map((tag) => {
        const { property, name } = tag

        // Add extra parameters to image meta tags (usually to resize them)
        const updatedImageTag = getImageMetaAttribute(tag)
        
        const propertyOrName = property || name
        const isTitle = ['og:title', 'twitter:title'].includes(propertyOrName)
        const isCopy = isTitle || ['description', 'og:description', 'twitter:description'].includes(propertyOrName)
        const isImageDimension = property === 'og:image:width' || property === 'og:image:height'
        const isLocale = propertyOrName === 'og:locale'

        // Default to the raw tag content
        let content = updatedImageTag.content

        // Use base title tag for all titles
        if (isTitle) content = title

        // Translate copy
        else if (isCopy) content = updatedImageTag.content
        
        // Use 1920x1080 for image dimensions
        else if (isImageDimension) content = property.includes('width') ? '1920' : '1080'

        // Use locale defined by i18n
        else if (isLocale) content = locale
        
        return {
          ...updatedImageTag,
          hid: propertyOrName,
          content
        }
      })

    const links = tags
      .filter(getLink)
      .map(getAttributes)

    return {
      title,
      meta: updatedMetaTags,
      link: links
    }
  }

  return {
    name: 'head',
    provide: {
      head: buildHead
    }
  }
})
