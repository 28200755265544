import background from './background.mjs'
import { imgproxySettings, mobileImgproxySettings } from './shared.mjs'

export const body = {
  id: 'body',
  name: 'Body',
  imgproxySettings,
  mobileImgproxySettings,
  hasOptions: false,
  pathTemplate: 'gs://sg360/220520/Ltd Editions/airas-le_wiv/airas-le_wiv_{nnn}.png'
}

export default {
  layers: [
    background,
    body
  ],
  resolveOptions: () => ({})
}