import { createError, useRuntimeConfig } from '#imports'
import { createStorefrontApiClient } from '@shopify/storefront-api-client'

import { print, type DocumentNode } from 'graphql'
import { useShopifyStore } from '~/stores/shopify'

import type { GQLExtensions } from '@shopify/graphql-client'

interface RequestOptions<T> {
  variables?: T
}

// This isn't exported from @shopify/storefront-api-client
type ResponseErrors = {
  networkStatusCode?: number
  message?: string
  graphQLErrors?: any[]
  response?: Response
}

interface HasExtensions {
  extensions: GQLExtensions
}

type ShopifyResponse<T> = T & HasExtensions

export default function useShopifyClient() {
  const {
    public: { shopify },
  } = useRuntimeConfig()

  const client = createStorefrontApiClient({
    storeDomain: shopify.storeDomain,
    apiVersion: '2024-07',
    publicAccessToken: shopify.storefrontAccessToken,
  })

  async function shopifyRequest<T>(
    key: string,
    query: string | DocumentNode,
    options: RequestOptions<Record<string, any>> = {}
  ): Promise<ShopifyResponse<T>> {
    const {
      country: { isoCode: country },
    } = useShopifyStore()

    // Ensure query is a string
    if (typeof query !== 'string') {
      // If query is a DocumentNode, convert it to string otherwise
      // it will throw an error when passed to client.request
      query = print(query)
    }

    // Inject country variable to the query
    options.variables = { ...options.variables, country }

    let response
    try {
      // Make the request with the provided type, query and options
      response = await client.request<T & { extensions: GQLExtensions }>(
        query,
        options
      )
    } catch (error) {
      // If this is ever reached it'd be a client error
      // rather than an error with the query
      throw createError({
        // @ts-expect-error: Should expect an error from Shopify
        message: `Shopify: ${error.message || 'Shopify fetch error'}`,
      })
    }

    // Check for any errors
    const errors = response.errors as ResponseErrors
    if (errors) {
      // Attempt to get a useful error message
      const errorMessage = errors?.graphQLErrors
        ?.map(
          ({ message, locations }) =>
            `${message} @ ${JSON.stringify(locations)}`
        )
        .join(', ')

      throw createError({
        message:
          `Shopify [${key}]: ` +
          (errorMessage || errors?.message || 'Shopify fetch error'),
        data: JSON.stringify(errors),
      })
    }

    // Inject the extensions into the response
    return {
      extensions: response.extensions || {},
      ...response.data,
    } as ShopifyResponse<T>
  }

  return { client, shopifyRequest }
}
