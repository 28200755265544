import md from "markdown-it";

export default defineNuxtPlugin(() => {
  const renderer = md({
    runtime: true,
    breaks: true,
    html: true,
    injected: true,
    xhtmlOut: true
  });
  return {
    provide: {
      md: renderer,
    },
  };
});
