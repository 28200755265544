import background from './background.mjs'
import {
  imgproxySettings,
  mobileImgproxySettings,
  resolveLifestyleSelectedOptions as resolveOptions,
  resolveLifestyleOptionsFromSKU as resolveOptionsFromSKU
} from './shared.mjs'

export const frame = {
  id: 'frame',
  name: 'Frame',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    Fclear: {
      pathTemplate: 'gs://sg360/220520/Renegades/rgf/rgf_Fclear_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230406/Renegades/rgf_Fclear_{nnn}-b.png'
    },
    Iclear: {
      pathTemplate: 'gs://sg360/220520/Renegades/rgf/rgf_Fclear_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230406/Renegades/rgf_Fclear_{nnn}-b.png'
    },
    Igrey: {
      pathTemplate: 'gs://sg360/220520/Renegades/rgf/rgf_Idarkgrey_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230406/Renegades/rgf_Idarkgrey_{nnn}.png'
    },
    Iblack: {
      pathTemplate: 'gs://sg360/220520/Renegades/rgf/rgf_Iblack_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230406/Renegades/rgf_Iblack_{nnn}.png'
    },
    Inavy: {
      pathTemplate: 'gs://sg360/220520/Renegades/rgf/rgf_Inavy_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230406/Renegades/rgf_Inavy_{nnn}.png'
    },
    Mblack: {
      pathTemplate: 'gs://sg360/220520/Renegades/rgf/rgf_Mblack_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230406/Renegades/rgf_Mblack_{nnn}.png'
    },
    Mblacktortoise: {
      pathTemplate: 'gs://sg360/220520/Renegades/rgf/rgf_Mblacktortoise_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230406/Renegades/rgf_Mblacktortoise_{nnn}-b.png'
    },
    Iblacktortoise: {
      pathTemplate: 'gs://sg360/220520/Renegades/rgf/rgf_Mblacktortoise_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230406/Renegades/rgf_Mblacktortoise_{nnn}-b.png'
    },
    Mcarbon: {
      pathTemplate: 'gs://sg360/220520/Renegades/rgf/rgf_Mcarbon_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230406/Renegades/rgf_Mcarbon_{nnn}.png'
    },
    Mgrey: {
      pathTemplate: 'gs://sg360/220520/Renegades/rgf/rgf_Mgrey_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230406/Renegades/rgf_Mgrey_{nnn}.png'
    },
    Mnavy: {
      pathTemplate: 'gs://sg360/220520/Renegades/rgf/rgf_Mnavy_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230406/Renegades/rgf_Mnavy_{nnn}.png'
    },
    Mstone: {
      pathTemplate: 'gs://sg360/220520/Renegades/rgf/rgf_Mstone_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230406/Renegades/rgf_Mstone_{nnn}.png'
    },
    Mtortoise: {
      pathTemplate: 'gs://sg360/220520/Renegades/rgf/rgf_Mtortoise_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230406/Renegades/rgf_Mtortoise_{nnn}.png'
    },
    Itortoise: {
      pathTemplate: 'gs://sg360/220520/Renegades/rgf/rgf_Mtortoise_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230406/Renegades/rgf_Mtortoise_{nnn}.png'
    },
    Mwhite: {
      pathTemplate: 'gs://sg360/220520/Renegades/rgf/rgf_Mwhite_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230406/Renegades/rgf_Mwhite_{nnn}.png'
    },
    Iwhite: {
      pathTemplate: 'gs://sg360/220520/Renegades/rgf/rgf_Mwhite_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230406/Renegades/rgf_Mwhite_{nnn}.png'
    }
  }
}

export const icons = {
  id: 'icons',
  name: 'Icons',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    Bblack: {
      pathTemplate: 'gs://sg360/220520/Renegades/rgi/rgi_Bblack_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230406/Renegades/rgi_Bblack_{nnn}.png'
    },
    Bgunmetal: {
      pathTemplate: 'gs://sg360/220520/Renegades/rgi/rgi_Bblack_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230406/Renegades/rgi_Bblack_{nnn}.png'
    },
    Bblue: {
      pathTemplate: 'gs://sg360/220520/Renegades/rgi/rgi_Bblue_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230406/Renegades/rgi_Bblue_{nnn}.png'
    },
    Bgold: {
      pathTemplate: 'gs://sg360/220520/Renegades/rgi/rgi_Bgold_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230406/Renegades/rgi_Bgold_{nnn}.png'
    },
    Bgreen: {
      pathTemplate: 'gs://sg360/220520/Renegades/rgi/rgi_Bgreen_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230406/Renegades/rgi_Bgreen_{nnn}.png'
    },
    Bpurple: {
      pathTemplate: 'gs://sg360/220520/Renegades/rgi/rgi_Bpurple_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230406/Renegades/rgi_Bpurple_{nnn}.png'
    },
    Bred: {
      pathTemplate: 'gs://sg360/220520/Renegades/rgi/rgi_Bred_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230406/Renegades/rgi_Bred_{nnn}.png'
    },
    Bsilver: {
      pathTemplate: 'gs://sg360/220520/Renegades/rgi/rgi_Bsilver_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230406/Renegades/rgi_Bsilver_{nnn}.png'
    },
    Bwhite: {
      pathTemplate: 'gs://sg360/220520/Renegades/rgi/rgi_Bwhite_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230406/Renegades/rgi_Bwhite_{nnn}.png'
    },
    Brosegold: {
      pathTemplate: 'gs://sg360/241129/rgi_Brosegold_{nnn}.png',
      staticPathTemplate: 'gs://sg360/241129/rgi_Brosegold_{nnn}.png'
    }
  }
}

export const lenses = {
  id: 'lens',
  name: 'Lenses',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    blue: {
      pathTemplate: 'gs://sg360/220520/Renegades/rgle/rgle_blue_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230406/Renegades/rgle_blue_{nnn}.png'
    },
    fire: {
      pathTemplate: 'gs://sg360/220520/Renegades/rgle/rgle_fire_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230406/Renegades/rgle_fire_{nnn}.png'
    },
    gold: {
      pathTemplate: 'gs://sg360/220520/Renegades/rgle/rgle_gold_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230406/Renegades/rgle_gold_{nnn}.png'
    },
    green: {
      pathTemplate: 'gs://sg360/220520/Renegades/rgle/rgle_green_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230406/Renegades/rgle_green_{nnn}.png'
    },
    purple: {
      pathTemplate: 'gs://sg360/220520/Renegades/rgle/rgle_purple_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230406/Renegades/rgle_purple_{nnn}.png'
    },
    red: {
      pathTemplate: 'gs://sg360/220520/Renegades/rgle/rgle_red_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230406/Renegades/rgle_red_{nnn}.png'
    },
    silverblue: {
      pathTemplate: 'gs://sg360/220520/Renegades/rgle/rgle_silverblue_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230406/Renegades/rgle_silverblue_{nnn}.png'
    },
    smoke: {
      pathTemplate: 'gs://sg360/220520/Renegades/rgle/rgle_smoke_{nnn}.png',
      staticPathTemplate: 'gs://sg360/230406/Renegades/rgle_smoke_{nnn}.png'
    },
    clear: {
      pathTemplate: 'gs://sg360/270624/Renegades/rgle/rgle_clear_{nnn}.png',
      staticPathTemplate: 'gs://sg360/270624/Renegades/rgle/rgle_clear_{nnn}.png'
    }
  }
}

export const reflection = {
  id: 'reflections',
  isReflection: true,
  name: 'Reflections',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    none: {
      disabled: true
    },
    r5pc: {
      pathTemplate: 'gs://sg360/031221/rgle_reflection/rgle_Cloud Opacity 5_{nnn}.png'
    },
    r10pc: {
      pathTemplate: 'gs://sg360/031221/rgle_reflection/rle_Cloud Opacity 10_{nnn}.png'
    }
  }
}

export const engraving = {
  id: 'engraving',
  name: 'Engraving',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    eN: {
      pathTemplate: 'gs://sg360/220520/Renegades/rglg/rglg_N_{nnn}.png'
    },
    eP: {
      pathTemplate: 'gs://sg360/220520/Renegades/rglg/rglg_P_{nnn}.png'
    },
    e8: {
      pathTemplate: 'gs://sg360/220520/Renegades/rglg/rglg_8_{nnn}.png'
    },
    e8P: {
      pathTemplate: 'gs://sg360/220520/Renegades/rglg/rglg_8P_{nnn}.png'
    },
    none: {
      disabled: true
    }
  }
}

export default {
  layers: [
    background,
    frame,
    icons,
    lenses,
    engraving,
    reflection
  ],
  resolveOptions,
  resolveOptionsFromSKU
}