import { v4 as uuidv4 } from 'uuid';

export const useUserStore = defineStore('user', {
  state: () => {
    return {
      email: '',
      referrer: '',
      uuid: '',
      shippingAddress: {},
      billingAddress: {},
      supportArticleRatings: {},
      gclid: ''
    }
  },
  getters: () => {
    return {
      email: this.email
    }
  },
  actions: {
    setUserEmail(value) {
      this.email = value
    },
    setReferrer (value) {
      this.referrer = value
    },
    setUUID () {
      if (this.uuid) return
      this.uuid = uuidv4()
    },
    rateSupportArticle (articleId, rating) {
      this.supportArticleRatings[articleId] = rating
    },
    setGoogleClickID (gclid) {
      this.gclid = gclid || this.gclid || ''
    }
  },

  persist: {
    storage: piniaPluginPersistedstate.localStorage(),
    pick: ['email', 'referrer', 'uuid', 'supportArticleRatings', 'gclid'],
  }
})
