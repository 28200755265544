import mitt from 'mitt'

export default defineNuxtPlugin(() => {
  const emitter = mitt()

  emitter.$on = emitter.on
  emitter.$off = emitter.off
  emitter.$emit = emitter.emit

  // emitter.$on('*', console.log)

  return {
    provide: {
      eventBus: emitter
    }
  }
})