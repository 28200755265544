import { getOrderPayload, testShouldTrack } from "./cart"
import { getFBProductContentPayload } from "./product"
import pinterest from "./pinterest"
import reddit from "./reddit"

const lastSteps = {
  step: null,
  stepCompleted: null,
}

let hasStartedCheckout = false
let hasStartedExpressCheckout = false
let hasCompletedCheckout = false

const isDifferentStep = (step, event) => {
  if (lastSteps[event] !== step) {
    lastSteps[event] = step
    return true
  }
  return false
}

export const getFBOrderPayload = (order, customisedProducts) => {
  const contents =
    order.discreteLineItems
      ?.map((lineItem) => {
        const customisedProduct = customisedProducts?.[lineItem.id]
        if (!customisedProduct) return null
        return getFBProductContentPayload(customisedProduct)
      })
      ?.filter((item) => item !== null) || []

  return {
    content_category: "purchase",
    content_ids: contents.map((content) => content.id),
    content_type: "product",
    contents,
    currency: order.currencyCode,
    num_items: order.itemCount,
    value: order.totalAmountWithTaxesFloat,
    order_id: order.id,
  }
}

export default (ra, fbq) => ({
  async start({ order, customisedProducts }) {
    if (!testShouldTrack(order)) return

    if (hasStartedCheckout) return
    hasStartedCheckout = true

    fbq.track("InitiateCheckout", getFBOrderPayload(order, customisedProducts))
    await ra.track(
      "Checkout Started",
      await getOrderPayload(order, customisedProducts)
    )
  },

  async startExpress({ order }) {
    if (!testShouldTrack(order)) return

    if (hasStartedExpressCheckout) return
    hasStartedExpressCheckout = true

    fbq.track("InitiateCheckout", getFBOrderPayload(order))
    await ra.track("Express Checkout Started", await getOrderPayload(order))
  },

  async step({ order, customisedProducts }, stepDetails) {
    if (!testShouldTrack(order)) return

    if (!isDifferentStep(stepDetails.step_name, "step")) return

    const stepName = stepDetails.step_name
    const stepNameCapitalisedFirstLetter = stepName.charAt(0).toUpperCase()
      + stepName.slice(1)
    const eventName = `Checkout Step ${stepNameCapitalisedFirstLetter} Viewed`
    
    await ra.track(eventName, {
      ...(await getOrderPayload(order, customisedProducts)),
      ...stepDetails,
    })
  },

  async stepCompleted({ order, customisedProducts }, stepDetails) {
    if (!testShouldTrack(order)) return

    if (!isDifferentStep(stepDetails.step_name, "stepCompleted")) return

    const stepName = stepDetails.step_name
    const stepNameCapitalisedFirstLetter = stepName.charAt(0).toUpperCase()
      + stepName.slice(1)
    const eventName = `Checkout Step ${stepNameCapitalisedFirstLetter} Completed`

    await ra.track(eventName, {
      ...(await getOrderPayload(order, customisedProducts)),
      ...stepDetails,
    })
  },

  async completion({ order, customisedProducts }) {
    if (!testShouldTrack(order)) return
    
    if (hasCompletedCheckout) return
    hasCompletedCheckout = true

    // Build order payloads
    const orderPayload = await getOrderPayload(order, customisedProducts)
    const fbOrderPayload = getFBOrderPayload(order, customisedProducts)

    // Make sure we've got the latest user data
    ra.updateUserData()

    fbq.track("Purchase", fbOrderPayload)

    await Promise.all([
      ra.track(
        "Checkout Completed",
        orderPayload
      ),

      // GA4 on rudderstack specifically requires this event name
      ra.track("Order Completed", orderPayload),
    ])

    pinterest.checkout(fbOrderPayload)
    reddit.checkout(fbOrderPayload)
  },
})
