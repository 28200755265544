import gql from 'graphql-tag'

export const productFields = gql`
  fragment productFields on Product {
    id
    title
    tags
    isGiftCard
    handle
  }
`

export type ProductFields = {
  id: string
  title: string
  tags: string[]
  isGiftCard: boolean
  description: string
  descriptionHtml: string
  handle: string
}

export const productVariantFields = gql`
  fragment productVariantFields on ProductVariant {
    id
    sku
    title
    availableForSale
    quantityAvailable
    currentlyNotInStock
    price {
      amount
      currencyCode
    }
    compareAtPrice {
      amount
      currencyCode
    }
    inboundDeliveryDate: metafield(namespace: "custom", key: "next_delivery_date") {
      value
    }
    inboundDeliveryDateOverride: metafield(namespace: "custom", key: "_override_next_delivery_date") {
      value
    }
    preorderAsDelayedDespatch: metafield(namespace: "custom", key: "preorder_as_delayed_despatch") {
      value
    }
    prebuiltProductComponents: metafield(namespace: "custom", key: "prebuilt_product_components") {
      references(first: 10) {
        edges {
          node {
            ... on ProductVariant {
              id
              sku
            }
          }
        }
      }
    }
  }
`

export type ProductVariantFields = {
  id: string
  sku: string
  title: string
  availableForSale: boolean
  quantityAvailable: number
  currentlyNotInStock: boolean
  price: {
    amount: number
    currencyCode: string
  }
  prices?: {
    [currency: string]: number
  }
  compareAtPrice: {
    amount: number
    currencyCode: string
  } | null
  compareAtPrices?: {
    [currency: string]: number
  } | null
  inboundDeliveryDate: {
    value: string
  }
  inboundDeliveryDateOverride: {
    value: string
  }
  preorderAsDelayedDespatch: {
    value: string
  }
  prebuiltProductComponents: {
    references: {
      edges: {
        node: {
          id: string
          sku: string
        }
      }[]
    }
  }
}