export default ra => ({
  async linkClicked (link) {
    await ra.track('Navigation Link Clicked', {
      menuId: link.menuId,
      submenuId: link.submenuId,
      title: link.title,
      tierOne: link.tierOne,
      tierTwo: link.tierTwo,
      tierThree: link.tierThree
    })
  },

  async productClicked (product) {
    await ra.track('Menu Product Clicked', {
      sku: product.sku,
      title: product.title,
      location: product.location,
      target: product.target
    })
  },

  async showCurrencyMenu ({ currentCurrency, location }) {
    await ra.track('Currency Menu Opened', { currentCurrency, location })
  },

  async mobileMenuOpened () {
    await ra.track('Mobile Menu Opened')
  },

  async mobileMenuClosed () {
    await ra.track('Mobile Menu Closed')
  },

  async logoClicked () {
    await ra.track('Header Logo Clicked')
  },

  async cartClicked () {
    await ra.track('Header Cart Link Clicked')
  },
})