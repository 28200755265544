export const resolveUrlParams = (base, params) => {
  if (base.startsWith('/')) base = `https://www.sungod.co${base}`
  const url = new URL(base)

  for (const key in params) {
    url.searchParams.set(key, params[key])
  }
  
  return url.toString().replace(/^https:\/\/www.sungod.co/, '')
}

export const stripQueryParam = function ($route, param) {
  if (import.meta.server) return // Can't change the URL serverside without redirecting
  const query = { ...$route.query }
  delete query[param]
  const queryString = new URLSearchParams(query).toString()

  try {
    window.history.replaceState({}, '', `${$route.path}${queryString.length ? '?' : ''}${queryString}`)
  } catch {
    // Ignore the error
  }
}
