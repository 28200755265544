import { imgproxySettings, mobileImgproxySettings } from './shared.mjs'

export const body = {
  id: 'body',
  name: 'Body',
  imgproxySettings,
  mobileImgproxySettings,
  hasOptions: false,
  pathTemplate: 'gs://sg360/240418/Vu_McLaren_{nnn}.png'
}

export default {
  supports360: false,
  layers: [
    body
  ],
  resolveOptions: () => ({})
}