import { reactive } from 'vue'
import getterMixin from './getters'
import mutationMixin from './mutations'

const defaults = {
  choices: {},
  secondaryChoices: {},
  selectedUpsellChoices: [],
  defaultChoicesSet: false,
  conversionKitEnabled: false,
  lensTechSelected: false,
}

class CustomisedProductWithoutMixins {
  constructor(product, state = {}, queryParams) {
    if (!product) {
      throw new Error(
        'Cannot create CustomisedProduct without Product instance'
      )
    }

    this.product = product
    this.state = reactive({
      ...defaults,
      ...state,
      currency: product.currency,
    })
    this.hydrated = true

    this._memos = {}
    this._hashes = {}

    if (this.product.parts?.length) {
      if (state.sku) this.state.choices = this.getChoicesFromSKU(state.sku)
      else if (!this.state.defaultChoicesSet) {
        this.setDefaultChoices(queryParams)
      }
    }

    // Handle limited editions
    if (this.product.isLimitedEdition) this.setLimitedEditionDefaults()
  }

  getMemoized(key, fn) {
    if (this._hashes?.[key] === this._hash) return this._memos[key]
    this._memos[key] = fn()
    this._hashes[key] = this._hash
    return this._memos[key]
  }

  toJSON() {
    return this.state
  }

  static fromJSON(product, data) {
    return new CustomisedProduct(product, data)
  }
}

const CustomisedProduct = mutationMixin(
  getterMixin(CustomisedProductWithoutMixins)
)

export default CustomisedProduct
