export default ra => ({
  async stepViewed (step) {
    await ra.track('Step Viewed', {
      stepTitle: step.stepTitle,
      currentStepNumber: step.currentStepNumber,
      totalStepCount: step.totalStepCount,
      interactionSection: step.interactionSection,
      interactionCategory: step.interactionCategory
    })
  }
})
