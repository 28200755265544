import gql from 'graphql-tag'
import { cart, type Cart } from '~/graphql/shopify/fragments/cart'

export type UserErrors = {
  code: string
  field: string
  message: string
}

export const createCartQuery = gql`
  mutation cartCreate ($country: CountryCode!, $attributes: [AttributeInput!]) @inContext(country: $country) {
    cartCreate (input: {
      buyerIdentity: {
        countryCode: $country
      },
      attributes: $attributes
    }) {
      cart {
        ...cart
      }
      userErrors {
        code
        field
        message
      }
    }
  }
  ${cart}
`

export type CartCreateResponse = {
  cartCreate: {
    cart: Cart
    userErrors: UserErrors[]
  }
}

export const cartAttributesUpdate = gql`
  mutation cartAttributesUpdate($attributes: [AttributeInput!]!, $cartId: ID!) {
    cartAttributesUpdate(attributes: $attributes, cartId: $cartId) {
      cart {
        ...cart
      }
      userErrors {
        field
        message
      }
    }
  }
  ${cart}
`

export type CartAttributesUpdateResponse = {
  cartAttributesUpdate: {
    cart: Cart
    userErrors: UserErrors[]
  }
}

export const addLines = gql`
  mutation addLines ($country: CountryCode!, $cartId: ID!, $lines: [CartLineInput!]!) @inContext(country: $country) {
    cartLinesAdd(cartId: $cartId, lines: $lines) {
      cart {
        ...cart
      }
      userErrors {
        code
        field
        message
      }
    }
  }
  ${cart}
`

export type CartLinesAddResponse = {
  cartLinesAdd: {
    cart: Cart
    userErrors: UserErrors[]
  }
}

export const removeLines = gql`
  mutation removeLines ($country: CountryCode!, $cartId: ID!, $lineIds: [ID!]!) @inContext(country: $country) {
    cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
      cart {
        ...cart
      }
      userErrors {
        code
        field
        message
      }
    }
  }
  ${cart}
`

export type CartLinesRemoveResponse = {
  cartLinesRemove: {
    cart: Cart
    userErrors: UserErrors[]
  }
}

export const updateLines = gql`
  mutation updateLines ($country: CountryCode!, $cartId: ID!, $lines: [CartLineUpdateInput!]!) @inContext(country: $country) {
    cartLinesUpdate(cartId: $cartId, lines: $lines) {
      cart {
        ...cart
      }
      userErrors {
        code
        field
        message
      }
    }
  }
  ${cart}
`

export type CartLinesUpdateResponse = {
  cartLinesUpdate: {
    cart: Cart
    userErrors: UserErrors[]
  }
}

export const cartDiscountCodesUpdate = gql`
  mutation cartDiscountCodesUpdate ($cartId: ID!, $discountCodes: [String!]!) {
    cartDiscountCodesUpdate(cartId: $cartId, discountCodes: $discountCodes) {
      cart {
        ...cart
      }
      userErrors {
        code
        field
        message
      }
    }
  }
  ${cart}
`

export type CartDiscountCodesUpdateResponse = {
  cartDiscountCodesUpdate: {
    cart: Cart
    userErrors: UserErrors[]
  }
}

export const cartGiftCardCodesUpdate = gql`
mutation cartGiftCardCodesUpdate ($cartId: ID!, $giftCardCodes: [String!]!) {
  cartGiftCardCodesUpdate(cartId: $cartId, giftCardCodes: $giftCardCodes) {
    cart {
      ...cart
    }
    userErrors {
      code
      field
      message
    }
  }
}
${cart}
`

export type CartGiftCardCodesUpdateResponse = {
  cartGiftCardCodesUpdate: {
    cart: Cart
    userErrors: UserErrors[]
  }
}