const getProductPayload = customisedProduct => ({
  sku: customisedProduct.sku,
  legacy_sku: customisedProduct.legacySKU,
  base_sku: customisedProduct.baseSKU,
  name: customisedProduct.name,
  lens_choice_sku: customisedProduct.lensChoice?.sku,
  lens_choice_name: customisedProduct.lensChoice?.plaintextName,
  lens_tech: customisedProduct.lensTech
})

export default ra => ({
  async viewed (customisedProduct, category) {
    await ra.track('Convincer Block Viewed', {
      ...getProductPayload(customisedProduct),
      category
    })
  },

  async click (customisedProduct, params) {
    await ra.track('Convincer Block Clicked', {
      ...getProductPayload(customisedProduct),
      category: params.category,
      card: params.card,
      type: params.type
    })
  }
})