export default (ra, fbq) => ({
  async submitted (details) {
    await ra.track('Form Submitted Success', {
      form: details.formId || details,
      interactionElement: details.interactionElement,
      interactionCategory: details.interactionCategory,
      interactionSection: details.interactionSection,
      position: details.position
    })

    fbq.track('Lead', {
      form: details.formId
    })
  },

  async invalid (details) {
    await ra.track('Form Submitted Invalid', {
      form: details.formId || details,
      reason: details.reason,
      interactionElement: details.interactionElement,
      interactionCategory: details.interactionCategory,
      interactionSection: details.interactionSection,
      position: details.position
    })
  }
})
