import background from './background.mjs'
import {
  imgproxySettings,
  mobileImgproxySettings,
  getSKUWithoutPrefix,
  resolvePaceOptionsFromSKU as resolveOptionsFromSKU
} from './shared.mjs'

import { lenses, engraving, reflection, iris } from './airas.mjs'

const frameType = 'ZF'

export const ghost = {
  id: 'ghost',
  name: 'Ghost Frame',
  hasOptions: false,
  imgproxySettings,
  mobileImgproxySettings,
  opacity: 0.5,
  pathTemplate: `gs://sg360/220520/Ghost Frames/Airas/${frameType}/Airas ${frameType} Ghost_{nnn}.png`
}

function resolveOptions (customisedProduct) {
  return {
    engraving: 'eN',
    lens: getSKUWithoutPrefix(customisedProduct.lensChoice.skuWithoutLensTech) + frameType,
    iris: frameType,
    reflections: 'r5pc' + frameType
  }
}

export default {
  layers: [
    background,
    ghost,
    lenses,
    iris,
    engraving,
    reflection
  ],
  resolveOptions,
  resolveOptionsFromSKU
}