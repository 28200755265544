const _cache = []
const RE_TOKEN_LIST_VALUE = /^(?:\d)+/
const RE_TOKEN_NAMED_VALUE = /^(?:\w)+/

function isObject(obj) {
  return obj !== null && typeof obj === 'object'
}

function isString(val) {
  return typeof val === 'string'
}

function parse(format) {
  const tokens = []
  let position = 0

  let text = ''
  while (position < format.length) {
    let char = format[position++]
    if (char === '{') {
      if (text) {
        tokens.push({ type: 'text', value: text })
      }

      text = ''
      let sub = ''
      char = format[position++]
      while (char !== undefined && char !== '}') {
        sub += char
        char = format[position++]
      }
      const isClosed = char === '}'

      const type = RE_TOKEN_LIST_VALUE.test(sub)
        ? 'list'
        : isClosed && RE_TOKEN_NAMED_VALUE.test(sub)
        ? 'named'
        : 'unknown'
      tokens.push({ value: sub, type })
    } else if (char === '%') {
      // when found rails i18n syntax, skip text capture
      if (format[position] !== '{') {
        text += char
      }
    } else {
      text += char
    }
  }

  text && tokens.push({ type: 'text', value: text })

  return tokens
}

function interpolate(message, values) {
  if (!values) {
    return [message]
  }
  let tokens = _cache[message]
  if (!tokens) {
    tokens = parse(message)
    _cache[message] = tokens
  }
  return compile(tokens, values)
}

function compile(tokens, values) {
  const compiled = []
  let index = 0

  const mode = Array.isArray(values)
    ? 'list'
    : isObject(values)
    ? 'named'
    : 'unknown'
  if (mode === 'unknown') {
    return compiled
  }

  while (index < tokens.length) {
    const token = tokens[index]
    switch (token.type) {
      case 'text':
        compiled.push(token.value)
        break
      case 'list':
        compiled.push(values[parseInt(token.value, 10)])
        break
      case 'named':
        if (mode === 'named') {
          compiled.push(values[token.value])
        } else if (process.env.NODE_ENV !== 'production') {
          console.warn(
            "Type of token '" +
              token.type +
              "' and format of value '" +
              mode +
              "' don't match!"
          )
        }
        break
      case 'unknown':
        if (process.env.NODE_ENV !== 'production') {
          console.warn("Detect 'unknown' type of token!")
        }
        break
    }
    index++
  }

  return compiled
}

export default defineNuxtPlugin(() => {
  function translate(input, values, interpolateMode = 'string') {
    if (!input || typeof input !== 'string') return ''

    const ret = interpolate(input, values)

    return interpolateMode === 'string' && !isString(ret) ? ret.join('') : ret
  }

  function translatePlural(singular, plural, subject, ...args) {
    if (Array.isArray(subject)) subject = subject.length
    if (subject === 1) return this.$tr(singular, ...args)
    return this.$tr(plural, ...args)
  }

  function formatNumber(value, options) {
    const { $i18n } = useNuxtApp()
    const locale = $i18n.locale.value || 'en-gb'
    return new Intl.NumberFormat(locale, options).format(value)
  }

  function currency(value, digits) {
    const { storeCurrency } = useStorefront()

    if (storeCurrency.value === 'GBP')
      return (
        '£' +
        formatNumber(value, {
          maximumFractionDigits: digits,
          minimumFractionDigits: digits,
        })
      )
    return formatNumber(value, {
      style: 'currency',
      maximumFractionDigits: digits,
      minimumFractionDigits: digits,
      currency: storeCurrency.value,
    })
  }

  function currencyShort(value) {
    return currency.bind(this)(value, 0)
  }

  function currencyLong(value) {
    return currency.bind(this)(value, 2)
  }

  function currencyAuto(value) {
    return Number.isInteger(value)
      ? currencyShort.bind(this)(value)
      : currencyLong.bind(this)(value)
  }

  function percentage(number, digits = 1) {
    return formatNumber(number, {
      style: 'percent',
      maximumFractionDigits: digits,
      minimumFractionDigits: digits,
    })
  }

  function number(number, digits = 0) {
    return formatNumber(number, {
      maximumFractionDigits: digits,
      minimumFractionDigits: digits,
    })
  }

  return {
    name: 'translation-helper',
    provide: {
      n: formatNumber,
      tr: translate,
      trp: translatePlural,
      tca: currencyAuto,
      tcs: currencyShort,
      tcl: currencyLong,
      tp: percentage,
      tn: number,
    },
  }
})
