import {
  imgproxySettings,
  mobileImgproxySettings,
  resolveLifestyleSelectedOptions as resolveOptions,
  resolveLifestyleOptionsFromSKU as resolveOptionsFromSKU
} from './shared.mjs'

import { lenses } from './tempests.mjs'

export const ghost = {
  id: 'ghost',
  name: 'Ghost Frame',
  hasOptions: false,
  imgproxySettings,
  mobileImgproxySettings,
  opacity: 0.5,
  pathTemplate: 'gs://sg360/220520/Ghost Frames/SG09s/SG09s Ghost_{nnn}.png'
}

export default {
  layers: [
    ghost,
    lenses
  ],
  resolveOptions,
  resolveOptionsFromSKU
}