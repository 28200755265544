const getArticlePayload = article => ({
  articleId: article.id,
  title: article.title,
  link: article.link
})

export default ra => ({
  async viewArticle (article) {
    await ra.track('Support Article Viewed', getArticlePayload(article))
  },

  async rateArticle (article, rating) {
    await ra.track('Support Article Rated', {
      ...getArticlePayload(article),
      rating
    })
  },

  async search (query, resultsLength) {
    await ra.track('Support Articles Searched', { query, resultsLength })
  },

  async viewCategory (category) {
    await ra.track('Support Category Viewed', {
      categoryId: category.id,
      level: category.level,
      title: category.title,
      link: category.link
    })
  }
})