import background from './background.mjs'
import {
  imgproxySettings,
  mobileImgproxySettings,
  resolvePaceOptionsFromSKU as resolveOptionsFromSKU,
  getSKUWithoutPrefix
} from './shared.mjs'

export const frame = {
  id: 'frame',
  name: 'Frame',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    Iblack: {
      pathTemplate: 'gs://sg360/240514/gts-corrections/gtf_Iblack-{nnn}.png'
    },
    Iclear: {
      pathTemplate: 'gs://sg360/240514/gtf_Iclear-{nnn}.png'
    },
    Igrey: {
      pathTemplate: 'gs://sg360/240509/gtf_Igrey-{nnn}.png'
    },
    Islateblue: {
      pathTemplate: 'gs://sg360/240509/gtf_Islateblue-{nnn}.png'
    },
    Iwhite: {
      pathTemplate: 'gs://sg360/240514/gts-corrections/gtf_Rwhite-{nnn}.png'
    }
  }
}

export const earsocks = {
  id: 'earsocks',
  name: 'Earsocks',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    Rblack: {
      pathTemplate: 'gs://sg360/240514/gts-corrections/ges_Rblack-{nnn}.png'
    },
    Rgrey: {
      pathTemplate: 'gs://sg360/240509/ges_Rgrey-{nnn}.png'
    },
    Rpink: {
      pathTemplate: 'gs://sg360/240509/ges_Rpink-{nnn}.png'
    },
    Rslateblue: {
      pathTemplate: 'gs://sg360/240509/ges_Rslateblue-{nnn}.png'
    },
    Rwhite: {
      pathTemplate: 'gs://sg360/240514/gts-corrections/ges_Rwhite-{nnn}.png'
    }
  }
}

export const icons = {
  id: 'icons',
  name: 'Icons',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    Bblack: {
      pathTemplate: 'gs://sg360/240509/gti_Bblack-{nnn}.png'
    },
    Bblue: {
      pathTemplate: 'gs://sg360/240509/gti_Bblue-{nnn}.png'
    },
    Bgold: {
      pathTemplate: 'gs://sg360/240509/gti_Bgold-{nnn}.png'
    },
    Bgunmetal: {
      pathTemplate: 'gs://sg360/240509/gti_Bgunmetal-{nnn}.png'
    },
    Bpurple: {
      pathTemplate: 'gs://sg360/240509/gti_Bpurple-{nnn}.png'
    },
    Bred: {
      pathTemplate: 'gs://sg360/240509/gti_Bred-{nnn}.png'
    },
    Brosegold: {
      pathTemplate: 'gs://sg360/240509/gti_Brosegold-{nnn}.png'
    },
    Bsilver: {
      pathTemplate: 'gs://sg360/240509/gti_Bsilver-{nnn}.png'
    },
    Bwhite: {
      pathTemplate: 'gs://sg360/240509/gti_Bwhite-{nnn}.png'
    }
  }
}

export const lenses = {
  id: 'lens',
  name: 'Lenses',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    clear: {
      pathTemplate: 'gs://sg360/240514/gts-corrections/gtle_8clear-{nnn}.png'
    },
    gold: {
      pathTemplate: 'gs://sg360/240509/gtle_8gold-{nnn}.png'
    },
    chromecatfour: {
      pathTemplate: 'gs://sg360/240509/gtle_8chrome4-{nnn}.png'
    },
    hvblue: {
      pathTemplate: 'gs://sg360/240514/gts-corrections/gtle_8hvblue-{nnn}.png'
    },
    pink: {
      pathTemplate: 'gs://sg360/240709/gtle_8pink-{nnn}.png'
    },
    silverblue: {
      pathTemplate: 'gs://sg360/240514/gts-corrections/gtle_8silverblue-{nnn}.png'
    },
    smoke: {
      pathTemplate: 'gs://sg360/240509/gtle_8smoke-{nnn}.png'
    },
    IRhvblue: {
      pathTemplate: 'gs://sg360/240514/gts-corrections/gtle_8IRhvblue-{nnn}.png'
    },
    IRsmoke: {
      pathTemplate: 'gs://sg360/240509/gtle_8IRsmoke-{nnn}.png'
    },
    chrome4: {
      pathTemplate: 'gs://sg360/240815/gtle_8chrome4-{nnn}.png'
    }
  }
}

export const iris = {
  id: 'iris',
  name: 'Iris Mask',
  imgproxySettings,
  mobileImgproxySettings,
  isIrisMask: true,
  options: {
    none: {
      disabled: true
    },
    FF: {
      pathTemplate: 'gs://sg360/240514/gts-corrections/gtle_8IRmask-{nnn}.png'
    }
  }
}

function resolveOptions (customisedProduct) {
  const design = customisedProduct.state.choices

  return {
    frame: getSKUWithoutPrefix(design.gtf),
    icons: getSKUWithoutPrefix(design.i),
    earsocks: getSKUWithoutPrefix(design.ges),
    lens: getSKUWithoutPrefix(customisedProduct.lensChoice?.skuWithoutLensTech),
    iris: customisedProduct.frameType.toUpperCase(),
  }
}

function resolveSecondaryOptions () {
  return {}
}

export default {
  supports360: false,
  layers: [
    background,
    frame,
    earsocks,
    icons,
    lenses,
    iris
  ],
  resolveOptions,
  resolveSecondaryOptions,
  resolveOptionsFromSKU
}