import background from './background.mjs'
import { imgproxySettings, mobileImgproxySettings } from './shared.mjs'
import { reflection } from './renegades.mjs'

export const body = {
  id: 'body',
  name: 'Body',
  imgproxySettings,
  mobileImgproxySettings,
  hasOptions: false,
  pathTemplate: 'gs://sg360/220520/Ltd Editions/renegades-le_mclarenplus/renegades-le_mclarenplus_{nnn}.png'
}

export default {
  layers: [
    background,
    body,
    reflection
  ],
  resolveOptions: () => ({
    reflections: 'r5pc'
  })
}