import { uid } from 'uid'

export default {
  checkout(order) {
    if (!window.pintrk) return

    window.pintrk('track', 'checkout', {
      event_id: uid(32),
      value: order.value,
      order_quantity: order.num_items,
      currency: order.currency
    });
  },

  addToCart(product) {
    if (!window.pintrk) return

    window.pintrk('track', 'addtocart', {
      event_id: uid(32),
      value: product.value,
      order_quantity: 1,
      currency: product.currency
    });
  },

  pageVisit() {
    if (!window.pintrk) return

    window.pintrk('track', 'pagevisit', {
      event_id: uid(32),
    });
  },
}