import VueScrollTo from 'vue-scrollto';

export default defineNuxtPlugin(() => {
  return {
    name: 'scrollTo',
    provide: {
      scrollTo: VueScrollTo.scrollTo
    }
  };
});
