// https://github.com/WilliamDASILVA/nuxt-facebook-pixel-module/blob/dev/lib/module.js

import { Minimatch } from "minimatch"
import { useUserStore } from "@/stores/user"

/**
 * @class Fb
 */
class Fb {
  constructor(options) {
    this.eventsQueue = []
    this.fqbLoaded = false
    this.options = options
    this.fbq = null

    this.isEnabled = !options.disabled

    this.userStore = options.userStore || useUserStore()
  }

  setFbq(fbq) {
    this.fbq = fbq
    this.fqbLoaded = true

    this.send()
  }

  setPixelId(pixelId) {
    this.options.pixelId = pixelId
    this.init()
  }

  /**
   * @method setUserData
   * Used to set user data that'll be used once the `fbq` init function is called.
   * @param {object} [userData] See https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching#reference
   */
  setUserData(userData) {
    this.userData = userData
  }

  setUserDataFromContext(anonId) {
    const userData = {
      em: this.userStore.email?.toLowerCase()?.trim(),
    }

    // Normalise name - lowercase, strip whitespace and punctuation, encode utf8
    const normaliseName = (name) => {
      return name
        ?.toLowerCase()
        ?.trim()
        ?.replace(/[^a-z0-9]/g, "")
        ?.normalize("NFD")
        ?.replace(/[\u0300-\u036F]/g, "")
    }

    // Normalise phone number - strip whitespace, punctuation, leading zeros
    const normalisePhone = (phone) => {
      return phone?.replace(/[^0-9]/g, "")?.replace(/^0+/, "")
    }

    // Normalise address - lowercase, strip whitespace and punctuation, encode utf8
    const normaliseAddress = normaliseName

    // Normalise zip code - strip whitespace and punctuation, if looks like a US zip code then only take first 5 digits
    const normaliseZipCode = (zipCode) => {
      if (!zipCode) return
      if (zipCode.match(/^[0-9]{5}-/))
        return zipCode.replace(/[^0-9-]/g, "").split("-")[0]
      return normaliseName(zipCode.trim())
    }

    const address = this.userStore.shippingAddress
      || this.userStore.billingAddress
    if (address) {
      address.fn = normaliseName(address.firstName)
      address.ln = normaliseName(address.lastName)
      address.ph = normalisePhone(address.phone)
      address.ct = normaliseAddress(address.city)
      address.st = normaliseAddress(address.stateCode)
      address.zp = normaliseZipCode(address.zipCode)
      address.country = normaliseAddress(address.countryCode)
    }

    if (anonId) userData.external_id = anonId

    this.setUserData(userData)
  }

  /**
   * @method enable
   */
  enable() {
    this.isEnabled = true
    this.init()
    this.track()
  }

  /**
   * @method disable
   */
  disable() {
    this.isEnabled = false
  }

  /**
   * @method init
   */
  init() {
    this.query("init", this.options.pixelId, this.userData || undefined)
  }

  /**
   * @method track
   */
  track(event = null, parameters = null, eventID = null) {
    if (!event) {
      event = this.options.track
    }

    if (this.options.testEventCode) {
      parameters = parameters || {}
      parameters.test_event_code = this.options.testEventCode
    }

    if (!eventID) {
      this.query("trackSingle", this.options.pixelId, event, parameters)
    } else {
      this.query(
        "trackSingle",
        this.options.pixelId,
        event,
        parameters,
        eventID
      )
    }
  }

  /**
   * @method query
   * @param {string} cmd
   * @param {object} option
   * @param {object} parameters
   * @param {object} eventID
   */
  query(cmd, option, parameters = null, eventID = null) {
    if (this.options.debug)
      log(
        "Command:",
        cmd,
        "Option:",
        option,
        "Additional parameters:",
        parameters,
        "EventID:",
        eventID
      )
    if (!this.isEnabled) return

    this.eventsQueue.push({
      cmd,
      option,
      parameters,
      eventID,
    })

    this.send()
  }

  send() {
    if (!this.fqbLoaded) {
      return
    }

    while (this.eventsQueue.length) {
      const event = this.eventsQueue.shift()

      if (this.options.debug) log("Send event: ", event)

      if (event.eventID) {
        this.fbq(event.cmd, event.option, event.parameters, event.eventID)
      } else if (event.parameters) {
        this.fbq(event.cmd, event.option, event.parameters)
      } else {
        this.fbq(event.cmd, event.option)
      }
    }
  }
}

function getMatchingPixel(options, path) {
  return options.pixels.find((pixel) => {
    const routeIndex = pixel.routes.findIndex((route) => {
      const minimatch = new Minimatch(route)
      return minimatch.match(path)
    })

    return routeIndex !== -1
  })
}

function log(...messages) {
  console.info.apply(this, ["[nuxt-facebook-pixel-module]", ...messages])
}

export default (ctx, anonId) => {
  const route = useRoute()
  const userStore = useUserStore()
  const { public: config } = useRuntimeConfig()

  const parsedOptions = {
    pixelId: null,
    track: "PageView",
    autoPageView: false,
    version: "2.0",
    pixels: [],
    manualMode: false,
    disabled: false,
    debug: false,
    ...config.facebook,
    userStore
  }

  // If pixelId is numeric, convert it to string
  if (typeof parsedOptions.pixelId === "number") {
    parsedOptions.pixelId = parsedOptions.pixelId.toString()
  }

  const isProduction = config.environmentName === 'production'
  const isDev = !isProduction && !parsedOptions.debug
  if (isDev) {
    log('You are running in development mode. Set "debug: true" in your nuxt.config.js if you would like to trigger tracking events in local.')
  }

  if (isProduction && !parsedOptions.pixelId) {
    console.error('Pixel ID is missing')
  }

  const { path } = route
  const matchingPixel = getMatchingPixel(parsedOptions, path)

  const pixelOptions = Object.assign({}, matchingPixel || parsedOptions)

  const instance = new Fb(pixelOptions)
  instance.setUserDataFromContext(anonId)

  if (typeof window !== "undefined") {
    ((f, b, e, v, n, t, s) => {
      const onLoadCallback = () => {
        instance.setFbq(window.fbq)

        if (!isDev && !pixelOptions.disabled) {
          if (pixelOptions.manualMode) {
            window.fbq("set", "autoConfig", false, pixelOptions.pixelId)
          }

          instance.init()
        }
      }

      if (f.fbq) {
        onLoadCallback()
        return
      }

      n = f.fbq = function () {
        n.callMethod
          ? n.callMethod.apply(n, arguments)
          : n.queue.push(arguments)
      }
      if (!f._fbq) f._fbq = n
      n.push = n
      n.loaded = !0
      n.version = pixelOptions.version
      n.queue = []
      t = b.createElement(e)
      t.async = true
      t.defer = true
      t.src = v
      s = b.getElementsByTagName("body")[0]
      s.parentNode.appendChild(t, s)

      if (t.readyState) {
        t.onreadystatechange = function () {
          if (t.readyState === "loaded" || t.readyState === "complete") {
            t.onreadystatechange = null
            onLoadCallback()
          }
        }
      } else {
        t.onload = onLoadCallback
      }
    })(
      window,
      document,
      "script",
      "https://connect.facebook.net/en_US/fbevents.js"
    )
  }

  if (ctx.app && ctx.app.router) {
    const router = ctx.app.router
    router.afterEach(({ path }) => {
      /**
       * Change the current pixelId according to the route.
       */
      const matchingPixel = getMatchingPixel(parsedOptions, path)

      const pixelOptions = Object.assign({}, matchingPixel || parsedOptions)
      if (pixelOptions.pixelId !== instance.options.pixelId) {
        instance.setPixelId(pixelOptions.pixelId)
      }

      /**
       * Automatically track PageView
       */
      if (parsedOptions.autoPageView) {
        instance.track("PageView")
      }
    })
  }

  return instance
}
