export const mediaFragment = (
  mediaType = "image",
  imgixParams = null,
  autoFormat = true
) => {
  if (autoFormat) {
    if (imgixParams) {
      imgixParams = `${imgixParams.replace(/}\s*$/, "")}, auto: [format] }`
    } else {
      imgixParams = "{ auto: [format] }"
    }
  }

  return `${mediaType} {
    id
    alt
    height
    width
    title
    format
    src: url(imgixParams: ${imgixParams})
    focalPoint {
      x
      y
    }
    thumbhash
    video {
      muxPlaybackId
    }
  }`
}

export const responsiveMediaFragment = (
  mediaType = "image",
  imgixParams = null,
  autoFormat = true
) => {
  if (autoFormat) {
    if (imgixParams) {
      imgixParams = `${imgixParams.replace(/}\s*$/, "")}, auto: [format] }`
    } else {
      imgixParams = "{ auto: [format] }"
    }
  }

  return `${mediaType} {
    id
    responsiveImage${imgixParams ? `(imgixParams: ${imgixParams})` : ""} {
      srcSet
      webpSrcSet
      sizes
      src
      width
      height
      aspectRatio
      alt
      title
      bgColor
      base64
    }
    focalPoint {
      x
      y
    }
  }`
}

export const responsiveMediaTransparentFragment = (
  mediaType = "image",
  imgixParams = null
) =>
  `${mediaType} {
    id
    responsiveImage${imgixParams ? `(imgixParams: ${imgixParams})` : ""} {
      srcSet
      webpSrcSet
      sizes
      src
      width
      height
      aspectRatio
      alt
      title
    }
    focalPoint {
      x
      y
    }
  }`