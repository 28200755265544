import {
  responsiveMediaFragment
} from '~/gql/mediaFragments.gql'

import {
  seoFragment,
  dataCaptureBlock
} from '~/gql/fragments.gql'

import {
  singleImageRecordFragment,
  textWithBackgroundFragment,
  highlighterHeadingRecordFragment,
  inlineCTARecordFragment
} from './structuredText.gql'

const dialogContentBlocks = `
blocks {
  ${singleImageRecordFragment}
  ${textWithBackgroundFragment}
  ${highlighterHeadingRecordFragment}
  ${inlineCTARecordFragment}
  ... on DialogStackRecord {
    __typename
    _modelApiKey
    id
    heading
    subheading
  }
  ... on DialogComponentRecord {
    __typename
    _modelApiKey
    id
    componentName
  }
  ... on InlineDataCaptureFormRecord {
    __typename
    _modelApiKey
    id
    block {
      ${dataCaptureBlock}
    }
  }
}
`

export default `query DialogQuery($slug: String) {
  dialog(filter: { slug: { eq: $slug } }) {
    title
    slug
    ${seoFragment}
    fullScreen
    themeOverride
    contentLayout
    ${responsiveMediaFragment('bannerImage')}
    content {
      value
      ${dialogContentBlocks}
    }
    multipleColumn {
      content {
        value
        ${dialogContentBlocks}
      }
    }
  }
}`
