const getArticlePayload = article => ({
  slug: article.slug,
  title: article.title,
  is_pro_team_page: article.isProTeamPage,
  category: article.category.slug
})

export default ra => ({
  async viewArticle (article) {
    await ra.track('Explore Article Viewed', getArticlePayload(article))
  },

  async viewAthlete (athlete) {
    await ra.track('Explore Athlete Viewed', getArticlePayload(athlete))
  }
})