import markets from '~/assets/js/constants/markets'

import type { Market } from '../constants/markets'
import type { Locale } from '~/i18n/constants'

export type ResolvedMarket = Market & {
  id: number
}

export function getMarket (
  locale: string,
  environment: string,
  countries: Locale[]
): ResolvedMarket {
  const currency = countries.find((country) => {
    return country.code === locale
  })?.currency

  if (!currency) throw new Error('Unable to determine currency')

  return {
    ...markets[currency],
    id:
      environment === 'production'
        ? markets[currency].productionId
        : markets[currency].testId,
  }
}
