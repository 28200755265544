import renegades from './renegades.mjs'
import renegadeslrk from './renegadeslrk.mjs'
import classics3 from './classics3.mjs'
import classics3lrk from './classics3lrk.mjs'
import sierras from './sierras.mjs'
import sierraslrk from './sierraslrk.mjs'
import tempests from './tempests.mjs'
import tempestslrk from './tempestslrk.mjs'
import zephyrs from './zephyrs.mjs'
import zephyrslrk from './zephyrslrk.mjs'
import airas from './airas.mjs'
import airaslrk from './airaslrk.mjs'
import airasck from './airasck.mjs'
import vulcans from './vulcans.mjs'
import vulcanslrk from './vulcanslrk.mjs'
import vulcansck from './vulcansck.mjs'
import velans from './velans.mjs'
import velanslrk from './velanslrk.mjs'
import velansck from './velansck.mjs'
import ultras from './ultras.mjs'
import ultraslrk from './ultraslrk.mjs'
import classics3leRFU22 from './classics3-le_rfu22.mjs'
import classics3leDR3 from './classics3-le_dr3.mjs'
import classics3leAirton from './classics3-le_airton.mjs'
import classics3leSAS from './classics3-le_sas.mjs'
import renegadesleMcLaren from './renegades-le_mclaren.mjs'
import renegadesleMcLarenPlus from './renegades-le_mclarenplus.mjs'
import renegadesleWoodsy from './renegades-le_woodsy.mjs'
import renegadesleMcLarenMiami from './renegades-le_mclarenmiami.mjs'
import renegadesleMcLarenMiami23 from './renegades-le_mclarenmiami23.mjs'
import renegadesleSAS from './renegades-le_sas.mjs'
import sierrasleKatieO from './sierras-le_katieo.mjs'
import sierrasleLN4 from './sierras-le_ln4.mjs'
import sierrasleMcLaren from './sierras-le_mclaren.mjs'
import sierrasleMcLarenPlus from './sierras-le_mclarenplus.mjs'
import sierrasleMcLarenMonaco from './sierras-le_monaco.mjs'
import sierrasleSailGP from './sierras-le_sailgp.mjs'
import sierrasleSAS from './sierras-le_sas.mjs'
import sierrasleIneosBritannia from './sierras-le_ineosbritannia.mjs'
import tempestsleDR3 from './tempests-le_dr3.mjs'
import tempestsleMcLarenMX from './tempests-le_mclarenmx.mjs'
import tempestsleSailGP from './tempests-le_sailgp.mjs'
import tempestsleIneosBritannia from './tempests-le_ineosbritannia.mjs'
import airasleWiV from './airas-le_wiv.mjs'
import zephyrsleLN4 from './zephyrs-le_ln4.mjs'
import zephyrsleLN4clear from './zephyrs-le_ln4clear.mjs'
import zephyrsleMonaco23 from './zephyrs-le_monaco23.mjs'
import vulcansLeMomentum from './vulcans-le_momentum.mjs'
import velansLeMomentum from './velans-le_momentum.mjs'
import vulcansLeTekkerz from './vulcans-le_tekkerz.mjs'
import vulcansLeIneos from './vulcans-le_ineosgrenadiers.mjs'
import velansLeIneos from './velans-le_ineosgrenadiers.mjs'
import airasLeIneos from './airas-le_ineosgrenadiers.mjs'
import velansLeTomPidcock from './velans-le_tompidcock.mjs'
import tempestsLeMcLarenPlus from './tempests-le_mclarenplus.mjs'
import zephyrsLeAirton from './zephyrs-le_airton.mjs'
import tokas from './tokas.mjs'
import tokaslrk from './tokaslrk.mjs'
import zephyrsLeSilverstone from './zephyrs-le_silverstone.mjs'
import ultrasLeTomEvans from './ultras-le_tomevans.mjs'
import tokasLeMcLaren60th from './tokas-le_mclaren60th.mjs'
import vulcansLePauline from './vulcans-le_pauline.mjs'
import ultrasLeHayden from './ultras-le_hayden.mjs'
import renegadesLeMcLaren24 from './renegades-le_mclaren24.mjs'
import sierrasLeMcLaren24 from './sierras-le_mclaren24.mjs'
import zephyrsLeMcLaren24 from './zephyrs-le_mclaren24.mjs'
import vulcansLeMcLaren24 from './vulcans-le_mclaren24.mjs'
import sierrasLeMcLaren24Monaco from './sierras-le_mclaren24monaco.mjs'
import gts from './gts.mjs'
import gtslrk from './gtslrk.mjs'
import zephyrsLeSolstice from './zephyrs-le_solstice.mjs'
import tokasLeSolstice from './tokas-le_solstice.mjs'
import classics4LeWilliams from './classics4-le_williams.mjs'
import renegadesLeWlliams from './renegades-le_williams.mjs'
import classics4LePadres from './classics4-le_padres.mjs'
import classics4LePadresCC from './classics4-le_padrescc.mjs'
import renegadesLePadres from './renegades-le_padres.mjs'
import vulcansLePadres from './vulcans-le_padres.mjs'
import vulcansLePadresCC from './vulcans-le_padrescc.mjs'
import zephyrsLePadres from './zephyrs-le_padres.mjs'
import classics4LeCourtney from './classics4-le_courtney.mjs'
import miras from './miras.mjs'
import miraslrk from './miraslrk.mjs'
import mirasLeGrape from './miras-le_grape.mjs'
import mirasLeECB from './miras-le_ecb.mjs'
import renegadesLeECB from './renegades-le_ecb.mjs'
import renegadesLeAstros from './renegades-le_astros.mjs'
import renegadesLeDodgers from './renegades-le_dodgers.mjs'
import renegadesLeGiants from './renegades-le_giants.mjs'
import renegadesLeRedsox from './renegades-le_redsox.mjs'
import renegadesLeYankees from './renegades-le_yankees.mjs'
import zephyrsLeAstros from './zephyrs-le_astros.mjs'
import zephyrsLeDodgers from './zephyrs-le_dodgers.mjs'
import zephyrsLeGiants from './zephyrs-le_giants.mjs'
import zephyrsLeRedsox from './zephyrs-le_redsox.mjs'
import zephyrsLeYankees from './zephyrs-le_yankees.mjs'
import zephyrsLeMclaren24lasvegas from './zephyrs-le_mclaren24lasvegas.mjs'

const allLayerTemplates = {
  renegades,
  renegadeslrk,
  classics3,
  classics3lrk,
  sierras,
  sierraslrk,
  tempests,
  tempestslrk,
  zephyrs,
  zephyrslrk,
  airas,
  airaslrk,
  airasck,
  vulcans,
  vulcanslrk,
  vulcansck,
  velans,
  velanslrk,
  velansck,
  ultras,
  ultraslrk,
  tokas,
  tokaslrk,
  gts,
  gtslrk,
  miras,
  miraslrk,
  'classics3-le_rfu22': classics3leRFU22,
  'classics3-le_dr3': classics3leDR3,
  'classics3-le_airton': classics3leAirton,
  'classics3-le_sas': classics3leSAS,
  'renegades-le_mclaren': renegadesleMcLaren,
  'renegades-le_mclarenplus': renegadesleMcLarenPlus,
  'renegades-le_mclarenmiami': renegadesleMcLarenMiami,
  'renegades-le_mclarenmiami23': renegadesleMcLarenMiami23,
  'renegades-le_woodsy': renegadesleWoodsy,
  'renegades-le_sas': renegadesleSAS,
  'sierras-le_katieo': sierrasleKatieO,
  'sierras-le_ln4': sierrasleLN4,
  'sierras-le_mclaren': sierrasleMcLaren,
  'sierras-le_mclarenplus': sierrasleMcLarenPlus,
  'sierras-le_mclarenmonaco': sierrasleMcLarenMonaco,
  'sierras-le_sailgp': sierrasleSailGP,
  'sierras-le_sas': sierrasleSAS,
  'sierras-le_ineosbritannia': sierrasleIneosBritannia,
  'tempests-le_dr3': tempestsleDR3,
  'tempests-le_mclarenmx': tempestsleMcLarenMX,
  'tempests-le_sailgp': tempestsleSailGP,
  'tempests-le_ineosbritannia': tempestsleIneosBritannia,
  'airas-le_wiv': airasleWiV,
  'zephyrs-le_ln4': zephyrsleLN4,
  'zephyrs-le_ln4clear': zephyrsleLN4clear,
  'zephyrs-le_monaco23': zephyrsleMonaco23,
  'velans-le_momentum': velansLeMomentum,
  'vulcans-le_momentum': vulcansLeMomentum,
  'vulcans-le_tekkerz': vulcansLeTekkerz,
  'vulcans-le_ineosgrenadiers': vulcansLeIneos,
  'velans-le_ineosgrenadiers': velansLeIneos,
  'airas-le_ineosgrenadiers': airasLeIneos,
  'velans-le_tompidcock': velansLeTomPidcock,
  'tempests-le_mclarenplus': tempestsLeMcLarenPlus,
  'zephyrs-le_airton': zephyrsLeAirton,
  'zephyrs-le_silverstone': zephyrsLeSilverstone,
  'ultras-le_tomevans': ultrasLeTomEvans,
  'tokas-le_mclaren60th': tokasLeMcLaren60th,
  'vulcans-le_pauline': vulcansLePauline,
  'ultras-le_hayden': ultrasLeHayden,
  'renegades-le_mclaren24': renegadesLeMcLaren24,
  'sierras-le_mclaren24': sierrasLeMcLaren24,
  'sierras-le_mclaren24monaco': sierrasLeMcLaren24Monaco,
  'zephyrs-le_mclaren24': zephyrsLeMcLaren24,
  'vulcans-le_mclaren24': vulcansLeMcLaren24,
  'zephyrs-le_solstice': zephyrsLeSolstice,
  'tokas-le_solstice': tokasLeSolstice,
  'classics4-le_williams': classics4LeWilliams,
  'renegades-le_williams': renegadesLeWlliams,
  'classics4-le_padres': classics4LePadres,
  'classics4-le_padrescc': classics4LePadresCC,
  'renegades-le_padres': renegadesLePadres,
  'vulcans-le_padres': vulcansLePadres,
  'vulcans-le_padrescc': vulcansLePadresCC,
  'zephyrs-le_padres': zephyrsLePadres,
  'classics4-le_courtney': classics4LeCourtney,
  'miras-le_grape': mirasLeGrape,
  'miras-le_ecb': mirasLeECB,
  'renegades-le_ecb': renegadesLeECB,
  'renegades-le_astros': renegadesLeAstros,
  'renegades-le_dodgers': renegadesLeDodgers,
  'renegades-le_giants': renegadesLeGiants,
  'renegades-le_redsox': renegadesLeRedsox,
  'renegades-le_yankees': renegadesLeYankees,
  'zephyrs-le_astros': zephyrsLeAstros,
  'zephyrs-le_dodgers': zephyrsLeDodgers,
  'zephyrs-le_giants': zephyrsLeGiants,
  'zephyrs-le_redsox': zephyrsLeRedsox,
  'zephyrs-le_yankees': zephyrsLeYankees,
  'zephyrs-le_mclaren24lasvegas': zephyrsLeMclaren24lasvegas
}

export function getLayerTemplate (baseSKU) {
  return allLayerTemplates[baseSKU]
}

export function hasLayerTemplate (baseSKU) {
  return !!allLayerTemplates[baseSKU]
}

export default allLayerTemplates
