export type Locale = {
  code: string
  iso: string
  dir: 'ltr' | 'rtl' // Assuming only 'ltr' and 'rtl' are valid directions.
  countryCode: string
  lang: string
  countryName: string
  currency: string
  dato: false | string
}

export const locales: Locale[] = [
  {
    code: 'en-gb',
    iso: 'en-GB',
    dir: 'ltr',
    countryCode: 'gb',
    lang: 'en',
    countryName: 'United Kingdom',
    currency: 'GBP',
    dato: 'en',
  },
  {
    code: 'en-au',
    iso: 'en-AU',
    dir: 'ltr',
    countryCode: 'au',
    lang: 'en',
    countryName: 'Australia',
    currency: 'AUD',
    dato: 'en_NZ',
  },
  {
    code: 'en-ca',
    iso: 'en-CA',
    dir: 'ltr',
    countryCode: 'ca',
    lang: 'en',
    countryName: 'Canada',
    currency: 'CAD',
    dato: 'en_US',
  },
  {
    code: 'en-ch',
    iso: 'en-CH',
    dir: 'ltr',
    countryCode: 'ch',
    lang: 'en',
    countryName: 'Switzerland',
    currency: 'CHF',
    dato: false,
  },
  {
    code: 'en-de',
    iso: 'en-DE',
    dir: 'ltr',
    countryCode: 'de',
    lang: 'en',
    countryName: 'Germany',
    currency: 'EUR',
    dato: false,
  },
  {
    code: 'en-fr',
    iso: 'en-FR',
    dir: 'ltr',
    countryCode: 'fr',
    lang: 'en',
    countryName: 'France',
    currency: 'EUR',
    dato: false,
  },
  {
    code: 'en-ie',
    iso: 'en-IE',
    dir: 'ltr',
    countryCode: 'ie',
    lang: 'en',
    countryName: 'Ireland',
    currency: 'EUR',
    dato: false,
  },
  {
    code: 'en-nz',
    iso: 'en-NZ',
    dir: 'ltr',
    countryCode: 'nz',
    lang: 'en',
    countryName: 'New Zealand',
    currency: 'NZD',
    dato: 'en_NZ',
  },
  {
    code: 'en-us',
    iso: 'en-US',
    dir: 'ltr',
    countryCode: 'us',
    lang: 'en',
    countryName: 'United States',
    currency: 'USD',
    dato: 'en_US',
  },
  {
    code: 'en-ae',
    iso: 'en-AE',
    dir: 'ltr',
    countryCode: 'ae',
    lang: 'en',
    countryName: 'United Arab Emirates',
    currency: 'USD',
    dato: false,
  },
  {
    code: 'en-br',
    iso: 'en-BR',
    dir: 'ltr',
    countryCode: 'br',
    lang: 'en',
    countryName: 'Brazil',
    currency: 'USD',
    dato: false,
  },
  {
    code: 'en-at',
    iso: 'en-AT',
    dir: 'ltr',
    countryCode: 'at',
    lang: 'en',
    countryName: 'Austria',
    currency: 'EUR',
    dato: false,
  },
  {
    code: 'en-be',
    iso: 'en-BE',
    dir: 'ltr',
    countryCode: 'be',
    lang: 'en',
    countryName: 'Belgium',
    currency: 'EUR',
    dato: false,
  },
  {
    code: 'en-cl',
    iso: 'en-CL',
    dir: 'ltr',
    countryCode: 'cl',
    lang: 'en',
    countryName: 'Chile',
    currency: 'USD',
    dato: false,
  },
  {
    code: 'en-cy',
    iso: 'en-CY',
    dir: 'ltr',
    countryCode: 'cy',
    lang: 'en',
    countryName: 'Cyprus',
    currency: 'EUR',
    dato: false,
  },
  {
    code: 'en-dk',
    iso: 'en-DK',
    dir: 'ltr',
    countryCode: 'dk',
    lang: 'en',
    countryName: 'Denmark',
    currency: 'EUR',
    dato: false,
  },
  {
    code: 'en-es',
    iso: 'en-ES',
    dir: 'ltr',
    countryCode: 'es',
    lang: 'en',
    countryName: 'Spain',
    currency: 'EUR',
    dato: false,
  },
  {
    code: 'en-fi',
    iso: 'en-FI',
    dir: 'ltr',
    countryCode: 'fi',
    lang: 'en',
    countryName: 'Finland',
    currency: 'EUR',
    dato: false,
  },
  {
    code: 'en-gr',
    iso: 'en-GR',
    dir: 'ltr',
    countryCode: 'gr',
    lang: 'en',
    countryName: 'Greece',
    currency: 'EUR',
    dato: false,
  },
  {
    code: 'en-hk',
    iso: 'en-HK',
    dir: 'ltr',
    countryCode: 'hk',
    lang: 'en',
    countryName: 'Hong Kong',
    currency: 'USD',
    dato: false,
  },
  {
    code: 'en-hr',
    iso: 'en-HR',
    dir: 'ltr',
    countryCode: 'hr',
    lang: 'en',
    countryName: 'Croatia',
    currency: 'EUR',
    dato: false,
  },
  {
    code: 'en-hu',
    iso: 'en-HU',
    dir: 'ltr',
    countryCode: 'hu',
    lang: 'en',
    countryName: 'Hungary',
    currency: 'EUR',
    dato: false,
  },
  {
    code: 'en-id',
    iso: 'en-ID',
    dir: 'ltr',
    countryCode: 'id',
    lang: 'en',
    countryName: 'Indonesia',
    currency: 'USD',
    dato: false,
  },
  {
    code: 'en-in',
    iso: 'en-IN',
    dir: 'ltr',
    countryCode: 'in',
    lang: 'en',
    countryName: 'India',
    currency: 'USD',
    dato: false,
  },
  {
    code: 'en-it',
    iso: 'en-IT',
    dir: 'ltr',
    countryCode: 'it',
    lang: 'en',
    countryName: 'Italy',
    currency: 'EUR',
    dato: false,
  },
  {
    code: 'en-jp',
    iso: 'en-JP',
    dir: 'ltr',
    countryCode: 'jp',
    lang: 'en',
    countryName: 'Japan',
    currency: 'USD',
    dato: false,
  },
  {
    code: 'en-lu',
    iso: 'en-LU',
    dir: 'ltr',
    countryCode: 'lu',
    lang: 'en',
    countryName: 'Luxembourg',
    currency: 'EUR',
    dato: false,
  },
  {
    code: 'en-mx',
    iso: 'en-MX',
    dir: 'ltr',
    countryCode: 'mx',
    lang: 'en',
    countryName: 'Mexico',
    currency: 'USD',
    dato: false,
  },
  {
    code: 'en-nl',
    iso: 'en-NL',
    dir: 'ltr',
    countryCode: 'nl',
    lang: 'en',
    countryName: 'Netherlands',
    currency: 'EUR',
    dato: false,
  },
  {
    code: 'en-no',
    iso: 'en-NO',
    dir: 'ltr',
    countryCode: 'no',
    lang: 'en',
    countryName: 'Norway',
    currency: 'EUR',
    dato: false,
  },
  {
    code: 'en-pl',
    iso: 'en-PL',
    dir: 'ltr',
    countryCode: 'pl',
    lang: 'en',
    countryName: 'Poland',
    currency: 'EUR',
    dato: false,
  },
  {
    code: 'en-pt',
    iso: 'en-PT',
    dir: 'ltr',
    countryCode: 'pt',
    lang: 'en',
    countryName: 'Portugal',
    currency: 'EUR',
    dato: false,
  },
  {
    code: 'en-sa',
    iso: 'en-SA',
    dir: 'ltr',
    countryCode: 'sa',
    lang: 'en',
    countryName: 'Saudi Arabia',
    currency: 'USD',
    dato: false,
  },
  {
    code: 'en-se',
    iso: 'en-SE',
    dir: 'ltr',
    countryCode: 'se',
    lang: 'en',
    countryName: 'Sweden',
    currency: 'EUR',
    dato: false,
  },
  {
    code: 'en-sg',
    iso: 'en-SG',
    dir: 'ltr',
    countryCode: 'sg',
    lang: 'en',
    countryName: 'Singapore',
    currency: 'USD',
    dato: false,
  },
  {
    code: 'en-za',
    iso: 'en-ZA',
    dir: 'ltr',
    countryCode: 'za',
    lang: 'en',
    countryName: 'South Africa',
    currency: 'USD',
    dato: false,
  },
]
