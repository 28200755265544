export const useAbTestStore = defineStore('abtest', {
  state: () => ({
    tests: {}
  }),

  actions: {
    INIT ({ headers, url }) {
      // Store test from headers, set by Fastly
      this.tests = Object.entries(headers || {}).reduce((acc, [key, value]) => {
        if (key?.match(/-abtest/)) {
          const matches = value.match(/test-name=(.+), bucket=(.+)/)
          if (!matches?.length) return acc
          acc[matches[1]] = {
            value: matches[2],
            header: key
          }
        }
        return acc
      }, {})
    
      // Override tests with query parameters
      for (const key of url.searchParams.keys()) {
        const value = url.searchParams.get(key)
        if (key?.match(/^ab_/)) {
          const testKey = key.replace(/^ab_/, '')
          this.tests[testKey] = {
            value,
            header: null
          }
        } else if (key?.match(/^hab/)) {
          // hab = hashed ab test
          // Use these to override tests without obviously exposing the test name
          // The hash has the format key:variantId and is then URL safe base64 encoded
          try {
            const encoded = key.replace(/^hab/, '')
            const decoded = Buffer.from(encoded, 'base64').toString('utf-8')
            const [testKey, value] = decoded.split(':')
  
            this.tests[testKey] = {
              value,
              header: null
            }
          } catch (e) {
            // Do nothing
            console.log(e)
          }
        }
      }
    }
  }
})
