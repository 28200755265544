import { imgproxySettings, mobileImgproxySettings } from './shared.mjs'

export const body = {
  id: 'body',
  name: 'Body',
  imgproxySettings,
  mobileImgproxySettings,
  hasOptions: false,
  pathTemplate: 'gs://sg360/240606/zephyrs-le_solstice-{nnn}.png'
}

export default {
  supports360: false,
  layers: [
    body
  ],
  resolveOptions: () => ({})
}