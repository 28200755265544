import background from './background.mjs'
import {
  imgproxySettings,
  mobileImgproxySettings,
  getSKUWithoutPrefix,
  resolvePaceOptionsFromSKU as resolveOptionsFromSKU
} from './shared.mjs'

import { lenses, iris } from './gts.mjs'

const frameType = 'FF'

export const ghost = {
  id: 'ghost',
  name: 'Ghost Frame',
  hasOptions: false,
  imgproxySettings,
  mobileImgproxySettings,
  opacity: 0.3,
  pathTemplate: `gs://sg360/240514/gts-corrections/gtf_Rwhite-{nnn}.png`
}

function resolveOptions (customisedProduct) {
  return {
    lens: getSKUWithoutPrefix(customisedProduct.lensChoice.skuWithoutLensTech),
    iris: frameType
  }
}

export default {
  supports360: false,
  layers: [
    background,
    ghost,
    lenses,
    iris
  ],
  resolveOptions,
  resolveOptionsFromSKU
}