export default (ra) => ({
  async seen (data) {
    await ra.track('Signup Modal Seen', data)
  },

  async closed (data) {
    await ra.track('Signup Modal Closed', data)
  },

  async interactedDiscount (data) {
    await ra.track('Discount Code Copied', data)
  },

  async exited (data) {
    await ra.track('Exit Gate Clicked', data)
  }
})
