const getCompetitionPayload = ({ slug, competitionName }) => ({
  competition_slug: slug,
  competition_name: competitionName
})

export default ra => ({
  viewed (competition) {
    ra.track('Competition Viewed', getCompetitionPayload(competition))
  },
  
  formSubmitted (competition) {
    ra.track('Competition Form Submitted', getCompetitionPayload(competition))
  },

  verified (competition) {
    ra.track('Competition Verified', getCompetitionPayload(competition))
  }
})