import payload_plugin_qLmFnukI99 from "/vercel/path0/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_4sVQNw7RlN from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/vercel/path0/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/vercel/path0/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/vercel/path0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_BqLkNUef68 from "/vercel/path0/node_modules/@nuxtjs/algolia/dist/runtime/plugin.mjs";
import plugin_Jozdw60ZsE from "/vercel/path0/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import plugin_iGLjBbor9q from "/vercel/path0/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import plugin_PSbK9A48ZQ from "/vercel/path0/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import _00_sentry_client_3cjTwoKJ1L from "/vercel/path0/plugins/00.sentry.client.ts";
import _02_i18n_qBoAflq3sY from "/vercel/path0/plugins/02.i18n.js";
import _05_abtest_6XaYOyEMNA from "/vercel/path0/plugins/05.abtest.js";
import _10_product_iE6CWtSJHj from "/vercel/path0/plugins/10.product.js";
import _15_track_client_1OMfWRKddn from "/vercel/path0/plugins/15.track.client.js";
import bem_ISDSBlp4oA from "/vercel/path0/plugins/bem.js";
import dimg_sooImhFNga from "/vercel/path0/plugins/dimg.js";
import errors_BP4MEm7jPX from "/vercel/path0/plugins/errors.js";
import eventBus_client_90Xc5iMiwH from "/vercel/path0/plugins/eventBus.client.js";
import head_EV6wdvsKWK from "/vercel/path0/plugins/head.js";
import markdownit_L5kJV8yeqz from "/vercel/path0/plugins/markdownit.js";
import mixins_8sPEw1oPXC from "/vercel/path0/plugins/mixins.js";
import scrollTo_client_BlufCvuufJ from "/vercel/path0/plugins/scrollTo.client.js";
import translation_helper_0v6mZ18362 from "/vercel/path0/plugins/translation-helper.js";
import upload_Xt87FG31yX from "/vercel/path0/plugins/upload.js";
import upsell_kXOoWFjSDK from "/vercel/path0/plugins/upsell.js";
export default [
  payload_plugin_qLmFnukI99,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_BqLkNUef68,
  plugin_Jozdw60ZsE,
  plugin_iGLjBbor9q,
  plugin_PSbK9A48ZQ,
  _00_sentry_client_3cjTwoKJ1L,
  _02_i18n_qBoAflq3sY,
  _05_abtest_6XaYOyEMNA,
  _10_product_iE6CWtSJHj,
  _15_track_client_1OMfWRKddn,
  bem_ISDSBlp4oA,
  dimg_sooImhFNga,
  errors_BP4MEm7jPX,
  eventBus_client_90Xc5iMiwH,
  head_EV6wdvsKWK,
  markdownit_L5kJV8yeqz,
  mixins_8sPEw1oPXC,
  scrollTo_client_BlufCvuufJ,
  translation_helper_0v6mZ18362,
  upload_Xt87FG31yX,
  upsell_kXOoWFjSDK
]