import {
  createServerRootMixin,
  // @ts-ignore
} from 'vue-instantsearch/vue3/es'
import algoliasearch from 'algoliasearch/lite'

export default defineNuxtPlugin((nuxtApp) => {
  const {
    public: {
      algolia: { apiKey, applicationId },
      algoliaIndex,
    },
  } = useRuntimeConfig()
  const searchClient = algoliasearch(applicationId, apiKey)

  nuxtApp.vueApp.mixin(
    createServerRootMixin({
      searchClient,
      indexName: algoliaIndex,
    })
  )

  nuxtApp.vueApp.mixin({
    methods: {
      b64DecodeUnicode(str) {
        function base64ToBytes(base64) {
          const binString = atob(base64)
          return Uint8Array.from(binString, (m) => m.codePointAt(0))
        }
        return new TextDecoder().decode(base64ToBytes(str))
      },
      throttleFunction(func, limit) {
        let lastFunc
        let lastRan
        return function () {
          const context = this
          const args = arguments
          if (!lastRan) {
            func.apply(context, args)
            lastRan = Date.now()
          } else {
            clearTimeout(lastFunc)
            lastFunc = setTimeout(function () {
              if (Date.now() - lastRan >= limit) {
                func.apply(context, args)
                lastRan = Date.now()
              }
            }, limit - (Date.now() - lastRan))
          }
        }
      },
    },
  })
})
