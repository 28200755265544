import { gql } from "graphql-tag";
import { cartFields } from "./cartFields";
import { cartLines } from "./cartLines";

import type { CartLines } from "./cartLines";
import type { CartFields } from "./cartFields";

export const cart = gql`
  fragment cart on Cart {
    ...cartFields
    ...cartLines
  }
  ${cartFields}
  ${cartLines}
`;

export type Cart = CartFields & CartLines;
export type CartResponse = {
  cart: Cart
}