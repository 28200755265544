const experiments = [
  {
    key: 'vtojune24',
    label: 'Enable VTO',
    variants: [
      {
        id: 'control',
        label: 'Hidden',
      },
      {
        id: 'variant',
        label: 'Visible',
      },
    ],
  }
]

export default defineNuxtPlugin(() => {
  const abTestStore = useAbTestStore()

  if (import.meta.server) {
    const headers = useRequestHeaders()
    
    const url = useRequestURL()
    abTestStore.INIT({ headers, url })
  }

  return {
    name: 'abt',
    provide: {
      abt: {
        variant: (key) => abTestStore.tests?.[key]?.value,
        active() {
          return experiments
            .filter((experiment) => experiment.key in abTestStore.tests)
            .map((experiment) => {
              return {
                ...experiment,
                activeVariant: abTestStore.tests[experiment.key]?.value,
              }
            })
        },
      },
    },
  }
})
