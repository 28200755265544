import background from './background.mjs'
import {
  imgproxySettings,
  mobileImgproxySettings,
  resolveLifestyleSelectedOptions as resolveOptions,
  resolveLifestyleOptionsFromSKU as resolveOptionsFromSKU
} from './shared.mjs'

import { lenses, engraving, reflection } from './renegades.mjs'

export const ghost = {
  id: 'ghost',
  name: 'Ghost Frame',
  hasOptions: false,
  imgproxySettings,
  mobileImgproxySettings,
  opacity: 0.5,
  pathTemplate: 'gs://sg360/220520/Ghost Frames/Renegades/Renegades Ghost_{nnn}.png'
}

export default {
  layers: [
    background,
    ghost,
    lenses,
    engraving,
    reflection
  ],
  resolveOptions,
  resolveOptionsFromSKU
}