import { reactive } from 'vue'

export default class Swatch {
  constructor (data) {
    this._rawData = data

    this.id = data.id
    this.styles = data.styles
    this.image = data.image

    // Trick Vue into thinking this instance already has an observer to prevent it adding a new one
    this.__ob__ = reactive({}).__ob__
  }

  toJSON () {
    return this._rawData
  }

  static fromJSON (data) {
    return new Swatch(data)
  }
}
