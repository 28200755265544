import { getOrderPayload } from './cart'

export default ra => ({
  async applied (order, coupon) {
    await ra.track('Coupon Applied', {
      ...(await getOrderPayload(order)),
      submitted_coupon_code: coupon
    })
  },

  async removed (order) {
    await ra.track('Coupon Removed', await getOrderPayload(order))
  },

  async denied (order, coupon) {
    await ra.track('Coupon Denied', {
      ...(await getOrderPayload(order)),
      submitted_coupon_code: coupon
    })
  }
})