import { imgproxySettings, mobileImgproxySettings } from './shared.mjs'

export const body = {
  id: 'body',
  name: 'Body',
  imgproxySettings,
  mobileImgproxySettings,
  hasOptions: false,
  pathTemplate: 'gs://sg360/220704/vulcans-le_tekkerz/vulcans-le_tekkerz_{nnn}.png'
}

export default {
  layers: [
    body
  ],
  resolveOptions: () => ({})
}