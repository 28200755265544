/**
 * This file is a copy of https://github.com/logaretm/villus/blob/main/packages/batch/src/index.ts
 * we have modified it to allow us to set a maxPseudoComplexity limit. See note below.
 */

/* eslint-disable no-unused-vars */
import type { GraphQLError } from 'graphql'
import {
  CombinedError,
  definePlugin,
  fetch as fetchPlugin,
  makeFetchOptions,
  mergeFetchOpts,
  parseResponse
} from 'villus'
import type { ClientPluginContext, ClientPluginOperation } from 'villus'

interface GraphQLResponse<TData> {
  data: TData
  errors: any
}

interface ParsedResponse<TData> {
  ok: boolean
  status: number
  statusText: string
  headers: Headers
  body: GraphQLResponse<TData> | null
}


interface BatchOptions {
  fetch?: typeof fetch
  timeout: number
  maxOperationCount: number
  maxPseudoComplexity?: number
  exclude?: (op: ClientPluginOperation, ctx: ClientPluginContext) => boolean
}

type BatchedGraphQLResponse = GraphQLResponse<unknown>[]

function resolveGlobalFetch(): typeof fetch | undefined {
  if (typeof window !== 'undefined' && 'fetch' in window && window.fetch) {
    return window.fetch.bind(window)
  }

  if (typeof global !== 'undefined' && 'fetch' in global) {
    return (global as any).fetch
  }

  if (typeof self !== 'undefined' && 'fetch' in self) {
    return self.fetch
  }

  return undefined
}

const defaultOpts = (): BatchOptions => ({
  fetch: resolveGlobalFetch(),
  timeout: 10,
  maxOperationCount: 10,
  maxPseudoComplexity: 300
})

export function batch(opts?: Partial<BatchOptions>) {
  const { fetch, timeout, maxOperationCount, maxPseudoComplexity } = {
    ...defaultOpts(),
    ...(opts || {})
  }
  const fetchPluginInstance = fetchPlugin({ fetch })

  let operations: {
    resolveOp: (r: any, opIdx: number, err?: Error) => void; body: string
  }[] = []
  let scheduledConsume: any

  return definePlugin(function batchPlugin(ctx) {
    const { useResult, opContext, operation } = ctx

    if (opts?.exclude?.(ctx.operation, ctx)) {
      return fetchPluginInstance(ctx)
    }

    async function consume() {
      const pending = operations
      const body = `[${operations.map(o => o.body).join(',')}]`
      const fetchOpts = mergeFetchOpts(opContext, { headers: {}, body })
      operations = []

      if (!fetch) {
        throw new Error('Could not resolve fetch, please provide a fetch function')
      }

      let response: ParsedResponse<unknown>
      try {
        response = await fetch(opContext.url as string, fetchOpts)
          .then(parseResponse)

        ctx.response = response
        const resInit: Partial<Response> = {
          ok: response.ok,
          status: response.status,
          statusText: response.statusText,
          headers: response.headers,
        }

        pending.forEach(function unBatchResult(o, oIdx) {
          const opResult = (
            response.body as unknown as BatchedGraphQLResponse | null
          )?.[oIdx]

          // the server returned a non-json response or an empty one
          if (!opResult) {
            
            o.resolveOp(
              {
                ...resInit,
                body: response.body,
              },
              oIdx,
              new Error('Received empty response for this operation from server'),
            )
            console.error(fetchOpts)
            return
          }

          o.resolveOp(
            {
              body: opResult,
              ...resInit,
            },
            oIdx,
          )
        })
      } catch (err) {
        // This usually mean a network fetch error which is limited to DNS lookup errors
        // or the user may not be connected to the internet, so it's safe to assume no data is in the response
        pending.forEach(function unBatchErrorResult(o, oIdx) {
          o.resolveOp(undefined, oIdx, err as Error)
        })
      }
    }

    return new Promise(resolve => {
      if (scheduledConsume) {
        clearTimeout(scheduledConsume)
      }

      if (operations.length >= maxOperationCount) {
        // consume the old array
        consume()
      }

      // We calculate a pseudoComplexity of every query by just counting the
      // total number of lines, presuming that each one is a field or a directive
      // This is a very naive approach but it's better than nothing.
      const pseudoComplexities = operations.map(o => {
        return o.body.split('\n').length
      })
      if (pseudoComplexities.reduce((a, b) => a + b, 0) > maxPseudoComplexity) {
        consume()
      }

      if (!opContext.body) {
        opContext.body = makeFetchOptions(operation, opContext).body
      }

      operations.push({
        resolveOp: (response: ParsedResponse<unknown>, opIdx, err) => {
          resolve(undefined)
          // Handle DNS errors
          if (err) {
            useResult(
              {
                data: null,
                error: new CombinedError({
                  response,
                  networkError: err,
                }),
              },
              true,
            )
            return
          }

          const data = response.body?.data || null
          if (!response.ok || !response.body) {
            const error = buildErrorObject(response, opIdx)

            useResult(
              {
                data,
                error,
              },
              true,
            )
            return
          }

          useResult(
            {
              data,
              error: response.body.errors
                ? new CombinedError({
                  response,
                  graphqlErrors: response.body.errors
                })
                : null,
            },
            true,
          )
        },
        body: opContext.body as string,
      })

      scheduledConsume = setTimeout(consume, timeout)
    })
  })
}

function buildErrorObject(response: ParsedResponse<unknown>, opIdx: number) {
  // It is possible than a non-200 response is returned with errors, it should be treated as GraphQL error
  const ctorOptions: {
    response: typeof response;
    graphqlErrors?: GraphQLError[];
    networkError?: Error
  } = {
    response,
  }

  if (Array.isArray(response.body)) {
    const opResponse = response.body[opIdx]
    ctorOptions.graphqlErrors = opResponse?.errors
  } else if (response.body?.errors) {
    ctorOptions.graphqlErrors = response.body.errors
  } else {
    ctorOptions.networkError = new Error(response.statusText)
  }

  return new CombinedError(ctorOptions)
}