export default (ra) => ({
  async opened (data) {
    await ra.track('Gift Selector Opened', data)
  },

  async closed (data) {
    await ra.track('Gift Selector Closed', data)
  },

  async skipped(data) {
    await ra.track('Gift Selector Clicked Skip', data)
  },

  async selectedGift (selectedCustomisedDiscountProduct) {
    if(!selectedCustomisedDiscountProduct.value) return

    await ra.track('Selected Gift', {
      baseSku: selectedCustomisedDiscountProduct.value.baseSKU,
      sku: selectedCustomisedDiscountProduct.value.sku,
      originalValue: selectedCustomisedDiscountProduct.value.price,
      position: selectedCustomisedDiscountProduct.value.state?.displayOrder,
      discountedAmountOff: selectedCustomisedDiscountProduct.value.state?.discountSummary?.discountAmountOff,
      discountedPercentOff: selectedCustomisedDiscountProduct.value.state?.discountSummary?.discountPercentageOff * 100,
      discountIsFree: selectedCustomisedDiscountProduct.value.state?.discountSummary?.discountIsFree
    })
  },

  async addGiftToCart (selectedCustomisedDiscountProduct) {
    if(!selectedCustomisedDiscountProduct) return

    await ra.track('Added Gift to Cart', {
      baseSKU: selectedCustomisedDiscountProduct.baseSKU,
      sku: selectedCustomisedDiscountProduct.sku,
      originalValue: selectedCustomisedDiscountProduct.price,
      position: selectedCustomisedDiscountProduct?.state?.displayOrder,
      discountedAmountOff: selectedCustomisedDiscountProduct?.state?.discountSummary?.discountAmountOff,
      discountedPercentOff: selectedCustomisedDiscountProduct?.state?.discountSummary?.discountPercentageOff * 100,
      discountIsFree: selectedCustomisedDiscountProduct?.state?.discountSummary?.discountIsFree
    })
  },

  async incrementGift(bundle) {
    if(!bundle) return

    await ra.track('Increment Gift', {
      baseSKU: bundle.summary.bundleBaseSku,
      sku: bundle.summary.bundleSku,
      originalValue: bundle.summary.bundleOriginalCost,
      position: bundle.summary.displayOrder,
      newQuantity: bundle.summary.bundleQuantity + 1
    })
  },

  async swapGift(selectedCustomisedDiscountProduct) {
    if(!selectedCustomisedDiscountProduct) return

    await ra.track('Swap Gift', {
      baseSKU: selectedCustomisedDiscountProduct.baseSKU,
      sku: selectedCustomisedDiscountProduct.sku,
      originalValue: selectedCustomisedDiscountProduct.price,
      position: selectedCustomisedDiscountProduct?.state?.displayOrder,
      discountedAmountOff: selectedCustomisedDiscountProduct?.state?.discountSummary?.discountAmountOff,
      discountedPercentOff: selectedCustomisedDiscountProduct?.state?.discountSummary?.discountPercentageOff * 100,
      discountIsFree: selectedCustomisedDiscountProduct?.state?.discountSummary?.discountIsFree
    })
  },

  async removeGift(removedBundle) {
    await ra.track('Remove Gift', {
      baseSKU: removedBundle.summary.bundleBaseSku,
      sku: removedBundle.summary.bundleSku,
      originalValue: removedBundle.summary.bundleOriginalCost,
      discountedAmountOff: removedBundle.summary.bundleCostDifference,
      quantity: removedBundle.summary.bundleQuantity
    })
  }
})
