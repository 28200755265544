import background from './background.mjs'
import {
  imgproxySettings,
  mobileImgproxySettings,
  resolvePaceOptionsFromSKU as resolveOptionsFromSKU,
  getSKUWithoutPrefix
} from './shared.mjs'

export const frame = {
  id: 'frame',
  name: 'Frame',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    Fclear: {
      pathTemplate: 'gs://sg360/220520/Airas/af/af_Fclear_{nnn}.png'
    },
    Iclear: {
      pathTemplate: 'gs://sg360/220520/Airas/af/af_Fclear_{nnn}.png'
    },
    Igrey: {
      pathTemplate: 'gs://sg360/220520/Airas/af/af_Idarkgrey_{nnn}.png'
    },
    Iblack: {
      pathTemplate: 'gs://sg360/220520/Airas/af/af_Iblack_{nnn}.png'
    },
    Inavy: {
      pathTemplate: 'gs://sg360/220520/Airas/af/af_Inavy_{nnn}.png'
    },
    Mblack: {
      pathTemplate: 'gs://sg360/220520/Airas/af/af_Mblack_{nnn}.png'
    },
    Mgrey: {
      pathTemplate: 'gs://sg360/220520/Airas/af/af_Mgrey_{nnn}.png'
    },
    Mmint: {
      pathTemplate: 'gs://sg360/220520/Airas/af/af_Mmint_{nnn}.png'
    },
    Mnavy: {
      pathTemplate: 'gs://sg360/220520/Airas/af/af_Mnavy_{nnn}.png'
    },
    Mpink: {
      pathTemplate: 'gs://sg360/220520/Airas/af/af_Mpink_{nnn}.png'
    },
    Mstone: {
      pathTemplate: 'gs://sg360/220520/Airas/af/af_Mstone_{nnn}.png'
    },
    Istone: {
      pathTemplate: 'gs://sg360/220520/Airas/af/af_Mstone_{nnn}.png'
    },
    Mwhite: {
      pathTemplate: 'gs://sg360/220520/Airas/af/af_Mwhite_{nnn}.png'
    },
    Iwhite: {
      pathTemplate: 'gs://sg360/220520/Airas/af/af_Mwhite_{nnn}.png'
    },
    Islateblue: {
      pathTemplate: 'gs://sg360/240409/slateblue/af_Islateblue_{nnn}.png'
    }
  }
}

export const bottom = {
  id: 'bottom',
  name: 'Bottom',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    none: {
      disabled: true
    },
    Fclear: {
      pathTemplate: 'gs://sg360/220520/Airas/ab/ab_Fclear_{nnn}.png'
    },
    Igrey: {
      pathTemplate: 'gs://sg360/220520/Airas/ab/ab_Idarkgrey_{nnn}.png'
    },
    Iblack: {
      pathTemplate: 'gs://sg360/220520/Airas/ab/ab_Iblack_{nnn}.png'
    },
    Inavy: {
      pathTemplate: 'gs://sg360/220520/Airas/ab/ab_Inavy_{nnn}.png'
    },
    Mblack: {
      pathTemplate: 'gs://sg360/220520/Airas/ab/ab_Mblack_{nnn}.png'
    },
    Mgrey: {
      pathTemplate: 'gs://sg360/220520/Airas/ab/ab_Mgrey_{nnn}.png'
    },
    Mmint: {
      pathTemplate: 'gs://sg360/220520/Airas/ab/ab_Mmint_{nnn}.png'
    },
    Mnavy: {
      pathTemplate: 'gs://sg360/220520/Airas/ab/ab_Mnavy_{nnn}.png'
    },
    Mpink: {
      pathTemplate: 'gs://sg360/220520/Airas/ab/ab_Mpink_{nnn}.png'
    },
    Mstone: {
      pathTemplate: 'gs://sg360/220520/Airas/ab/ab_Mstone_{nnn}.png'
    },
    Istone: {
      pathTemplate: 'gs://sg360/220520/Airas/ab/ab_Mstone_{nnn}.png'
    },
    Mwhite: {
      pathTemplate: 'gs://sg360/220520/Airas/ab/ab_Mwhite_{nnn}.png'
    },
    Iwhite: {
      pathTemplate: 'gs://sg360/220520/Airas/ab/ab_Mwhite_{nnn}.png'
    },
    Islateblue: {
      pathTemplate: 'gs://sg360/240409/slateblue/ab_Mslateblue_{nnn}.png'
    }
  }
}

export const nose = {
  id: 'nose',
  name: 'Nose',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    none: {
      disabled: true
    },
    Fclear: {
      pathTemplate: 'gs://sg360/220520/Airas/an/an_Fclear_{nnn}.png'
    },
    Igrey: {
      pathTemplate: 'gs://sg360/220520/Airas/an/an_Idarkgrey_{nnn}.png'
    },
    Iblack: {
      pathTemplate: 'gs://sg360/220520/Airas/an/an_Iblack_{nnn}.png'
    },
    Inavy: {
      pathTemplate: 'gs://sg360/220520/Airas/an/an_Inavy_{nnn}.png'
    },
    Mblack: {
      pathTemplate: 'gs://sg360/220520/Airas/an/an_Mblack_{nnn}.png'
    },
    Mgrey: {
      pathTemplate: 'gs://sg360/220520/Airas/an/an_Mgrey_{nnn}.png'
    },
    Mmint: {
      pathTemplate: 'gs://sg360/220520/Airas/an/an_Mmint_{nnn}.png'
    },
    Mnavy: {
      pathTemplate: 'gs://sg360/220520/Airas/an/an_Mnavy_{nnn}.png'
    },
    Mpink: {
      pathTemplate: 'gs://sg360/220520/Airas/an/an_Mpink_{nnn}.png'
    },
    Mstone: {
      pathTemplate: 'gs://sg360/220520/Airas/an/an_Mstone_{nnn}.png'
    },
    Istone: {
      pathTemplate: 'gs://sg360/220520/Airas/an/an_Mstone_{nnn}.png'
    },
    Mwhite: {
      pathTemplate: 'gs://sg360/220520/Airas/an/an_Mwhite_{nnn}.png'
    },
    Iwhite: {
      pathTemplate: 'gs://sg360/220520/Airas/an/an_Mwhite_{nnn}.png'
    },
    Islateblue: {
      pathTemplate: 'gs://sg360/240409/slateblue/an_Mslateblue_{nnn}.png'
    }
  }
}

export const earsocks = {
  id: 'earsocks',
  name: 'Earsocks',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    Rblue: {
      pathTemplate: 'gs://sg360/220520/Airas/aes/aes_Rblue_{nnn}.png'
    },
    Rgold: {
      pathTemplate: 'gs://sg360/220520/Airas/aes/aes_Rgold_{nnn}.png'
    },
    Rgrey: {
      pathTemplate: 'gs://sg360/220520/Airas/aes/aes_Rgrey_{nnn}.png'
    },
    Rnavy: {
      pathTemplate: 'gs://sg360/220520/Airas/aes/aes_Rnavy_{nnn}.png'
    },
    Rstone: {
      pathTemplate: 'gs://sg360/220520/Airas/aes/aes_Rstone_{nnn}.png'
    },
    Rblack: {
      pathTemplate: 'gs://sg360/220520/Airas/aes/aes_Rblack_{nnn}.png'
    },
    Rmint: {
      pathTemplate: 'gs://sg360/220520/Airas/aes/aes_Rmint_{nnn}.png'
    },
    Rwhite: {
      pathTemplate: 'gs://sg360/220520/Airas/aes/aes_Rwhite_{nnn}.png'
    },
    Rslateblue: {
      pathTemplate: 'gs://sg360/240409/slateblue/aes_Rslateblue_{nnn}.png'
    }
  }
}

export const icons = {
  id: 'icons',
  name: 'Icons',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    Bblack: {
      pathTemplate: 'gs://sg360/220520/Airas/ai/ai_Bblack_{nnn}.png'
    },
    Bblue: {
      pathTemplate: 'gs://sg360/220520/Airas/ai/ai_Bblue_{nnn}.png'
    },
    Bgold: {
      pathTemplate: 'gs://sg360/220520/Airas/ai/ai_Bgold_{nnn}.png'
    },
    Bgunmetal: {
      pathTemplate: 'gs://sg360/220520/Airas/ai/ai_Bgunmetal_{nnn}.png'
    },
    Bpurple: {
      pathTemplate: 'gs://sg360/220520/Airas/ai/ai_Bpurple_{nnn}.png'
    },
    Bred: {
      pathTemplate: 'gs://sg360/220520/Airas/ai/ai_Bred_{nnn}.png'
    },
    Brosegold: {
      pathTemplate: 'gs://sg360/220520/Airas/ai/ai_Brosegold_{nnn}.png'
    },
    Bsilver: {
      pathTemplate: 'gs://sg360/220520/Airas/ai/ai_Bsilver_{nnn}.png'
    },
    Bwhite: {
      pathTemplate: 'gs://sg360/220520/Airas/ai/ai_Bwhite_{nnn}.png'
    }
  }
}

export const lenses = {
  id: 'lens',
  name: 'Lenses',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    clearBF: {
      pathTemplate: 'gs://sg360/220520/Airas/ale/BF/ale_BF_clear_{nnn}.png'
    },
    fireBF: {
      pathTemplate: 'gs://sg360/220520/Airas/ale/BF/ale_BF_fire_{nnn}.png'
    },
    goldBF: {
      pathTemplate: 'gs://sg360/220520/Airas/ale/BF/ale_BF_gold_{nnn}.png'
    },
    greenBF: {
      pathTemplate: 'gs://sg360/220520/Airas/ale/BF/ale_BF_green_{nnn}.png'
    },
    hvblueBF: {
      pathTemplate: 'gs://sg360/220520/Airas/ale/BF/ale_BF_hvblue_{nnn}.png'
    },
    IRhvblueBF: {
      pathTemplate: 'gs://sg360/220520/Airas/ale/BF/ale_BF_IRhvblue_{nnn}.png'
    },
    IRsmokeBF: {
      pathTemplate: 'gs://sg360/220520/Airas/ale/BF/ale_BF_IRsmoke_{nnn}.png'
    },
    purpleBF: {
      pathTemplate: 'gs://sg360/220520/Airas/ale/BF/ale_BF_purple_{nnn}.png'
    },
    silverblueBF: {
      pathTemplate: 'gs://sg360/220520/Airas/ale/BF/ale_BF_silverblue_{nnn}.png'
    },
    smokeBF: {
      pathTemplate: 'gs://sg360/220520/Airas/ale/BF/ale_BF_smoke_{nnn}.png'
    },
    clearZF: {
      pathTemplate: 'gs://sg360/220520/Airas/ale/ZF/ale_ZF_clear_{nnn}.png'
    },
    fireZF: {
      pathTemplate: 'gs://sg360/220520/Airas/ale/ZF/ale_ZF_fire_{nnn}.png'
    },
    goldZF: {
      pathTemplate: 'gs://sg360/220520/Airas/ale/ZF/ale_ZF_gold_{nnn}.png'
    },
    greenZF: {
      pathTemplate: 'gs://sg360/220520/Airas/ale/ZF/ale_ZF_green_{nnn}.png'
    },
    hvblueZF: {
      pathTemplate: 'gs://sg360/220520/Airas/ale/ZF/ale_ZF_hvblue_{nnn}.png'
    },
    IRhvblueZF: {
      pathTemplate: 'gs://sg360/220520/Airas/ale/ZF/ale_ZF_IRhvblue_{nnn}.png'
    },
    IRsmokeZF: {
      pathTemplate: 'gs://sg360/220520/Airas/ale/ZF/ale_ZF_IRsmoke_{nnn}.png'
    },
    purpleZF: {
      pathTemplate: 'gs://sg360/220520/Airas/ale/ZF/ale_ZF_purple_{nnn}.png'
    },
    silverblueZF: {
      pathTemplate: 'gs://sg360/220520/Airas/ale/ZF/ale_ZF_silverblue_{nnn}.png'
    },
    smokeZF: {
      pathTemplate: 'gs://sg360/220520/Airas/ale/ZF/ale_ZF_smoke_{nnn}.png'
    },
    chrome4ZF: {
      pathTemplate: 'gs://sg360/240815/ale_TF_chrome4_{nnn}.png'
    },
    chrome4BF: {
      pathTemplate: 'gs://sg360/240815/ale_FF_chrome4_{nnn}.png'
    }
  }
}

export const iris = {
  id: 'iris',
  name: 'Iris Mask',
  imgproxySettings,
  mobileImgproxySettings,
  isIrisMask: true,
  options: {
    none: {
      disabled: true
    },
    BF: {
      pathTemplate: 'gs://sg360/220520/Airas/Iris Lens Mask/BF/ale_Iris Mask_BF_{nnn}.png'
    },
    ZF: {
      pathTemplate: 'gs://sg360/220520/Airas/Iris Lens Mask/ZF/ale_Iris Mask_ZF_{nnn}.png'
    }
  }
}

export const reflection = {
  id: 'reflections',
  isReflection: true,
  name: 'Reflections',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    none: {
      disabled: true
    },
    r5pcBF: {
      pathTemplate: 'gs://sg360/220520/Airas/Cloud Overlay/BF/5/ale_BF_Cloud Overlay 5_{nnn}.png'
    },
    r10pcBF: {
      pathTemplate: 'gs://sg360/220520/Airas/Cloud Overlay/BF/10/ale_BF_Cloud Overlay 10_{nnn}.png'
    },
    r5pcZF: {
      pathTemplate: 'gs://sg360/220520/Airas/Cloud Overlay/ZF/5/ale_ZF_Cloud Overlay 5_{nnn}.png'
    },
    r10pcZF: {
      pathTemplate: 'gs://sg360/220520/Airas/Cloud Overlay/ZF/5/ale_ZF_Cloud Overlay 10_{nnn}.png'
    }
  }
}

export const engraving = {
  id: 'engraving',
  name: 'Engraving',
  imgproxySettings,
  mobileImgproxySettings,
  options: {
    eN: {
      pathTemplate: 'gs://sg360/220520/Airas/alg/alg_N_{nnn}.png'
    }
  }
}

function resolveOptions (customisedProduct) {
  const design = customisedProduct.state.choices

  return {
    frame: getSKUWithoutPrefix(design.af),
    bottom: getSKUWithoutPrefix(design.cb) || 'none',
    nose: getSKUWithoutPrefix(design.cn) || 'none',
    icons: getSKUWithoutPrefix(design.i),
    engraving: 'eN',
    earsocks: getSKUWithoutPrefix(design.es),
    lens: getSKUWithoutPrefix(customisedProduct.lensChoice.skuWithoutLensTech) + customisedProduct.frameType.toUpperCase(),
    iris: customisedProduct.frameType.toUpperCase(),
    reflections: 'r5pc' + customisedProduct.frameType.toUpperCase()
  }
}

function resolveSecondaryOptions (customisedProduct) {
  const design = customisedProduct.state.secondaryChoices
  return {
    bottom: getSKUWithoutPrefix(design.cb) || 'none',
    nose: getSKUWithoutPrefix(design.cn) || 'none'
  }
}

export default {
  supports360: false,
  layers: [
    background,
    frame,
    earsocks,
    bottom,
    nose,
    icons,
    lenses,
    iris,
    engraving,
    reflection
  ],
  resolveOptions,
  resolveSecondaryOptions,
  resolveOptionsFromSKU
}
