import { reactive } from 'vue'

const fields = [
  'id',
  'key',
  'mode',
  'name',
  'order',
  'upsellProduct',
  'upsellProducts',
  'showForProducts'
]

export default class Upsell {
  constructor (data) {
    this._rawData = data

    fields.forEach(field => {
      this[field] = data[field]
    })

    this.key = this.key || this.mode

    this.leadPartSKUPrefix = data.leadParts?.skuPrefix
    
    // Trick Vue into thinking this instance already has an observer to prevent it adding a new one
    this.__ob__ = reactive({}).__ob__
  }

  get sku () {
    return this.upsellProduct?.sku
  }

  get baseSKU () {
    return this.upsellProduct.baseProduct?.sku || this.sku
  }

  isValidForProduct (product) {
    return this.showForProducts.some(p => p.id === product.id)
  }
}