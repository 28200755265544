import gql from 'graphql-tag'

import {
  productFields,
  type ProductFields,
  productVariantFields,
  type ProductVariantFields
} from '../fragments/product'

import {
  pageInfo,
  type PageInfo
} from '../fragments/common'

export const getProductStock = gql`
    query getProductStock (
      $query: String
    ) {
      productVariants(first: 100, query: $query) {
        nodes {
          id
          sku
          sellableOnlineQuantity
        }
      }
    }
  `

export const getGiftCardProduct = gql`
  query getGiftCardProduct($id: ID!, $country: CountryCode!) @inContext(country: $country) {
    product(id: $id) {
      id
      variants(first: 100) {
        nodes {
          id
          title
          price {
            amount
            currencyCode
          }
        }
      }
    }
  }
`

export const getProducts = gql`
  query getProducts (
    $query: String,
    $cursor: String,
    $country: CountryCode!
  ) @inContext(country: $country) {
    products(first: 140, query: $query, after: $cursor) {
      edges {
        node {
          ...productFields
          variants(first: 100) {
            edges {
              node {
                ...productVariantFields
              }
            }
          }
        }
      }
      pageInfo {
        ...pageInfo
      }
    }
  }
  ${productFields}
  ${productVariantFields}
  ${pageInfo}
`

export type GetGiftCardProductResponse = {
  product: {
    id: string,
    variants: {
      nodes: {
        id: string
        title: string,
        price: {
          amount: string
          currencyCode: string
        }
      }[]
    }
  }
}

export type Product = ProductFields & {
  variants: {
    edges: {
      node: ProductVariantFields
    }[]
  }
}

export type ProductsResponse = {
  products: {
    edges: {
      node: Product
    }[]
    pageInfo: PageInfo
  }
}


export type GetProductStockResponse = {
  productVariants: {
    nodes: {
      id: string
      sku: number
      sellableOnlineQuantity: number
    }[]
  }[]
}